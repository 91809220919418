import i18n from './_i18n';
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

// console.log('__vee_val', i18n.locale);

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: (_, values) => i18n.t(`validation.${rule}`, values),
  });
});

extend('isAmountValid', {
  validate(value, messageKey) {
    return messageKey.messageKey == 'success';
  },
  message: i18n.t('branch_dash.min_amount_error'),
  params: ['messageKey'],
});

extend('iban', {
  validate: (str) => {
    const val = str.trim().replace(/ +/g, '');
    const reg = /^(?:[A-Za-z0-9]{11,32})$/gm;
    return reg.test(val);
  },
  message: i18n.t('custom_messages.invalid_field_format', { field: 'IBAN' }),
});

extend('ip', {
  validate: (str) => {
    const val = String(str);
    const reg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return reg.test(val);
  },
  message: i18n.t('ips.invalid_ip'),
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
