import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';
import router from '../../router/index';

const paymentMethodsManagemet = {
    state: () => ({
        isLoading: false,
        depositProviders: [],
        withdrawProviders: [],

        isSubmitting: false,
        banks: [],
        fee_type: [],

        method: {}

    }),
    mutations: {
        SET_STATE(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {
        async GET_PROVIDERS({ commit }, isLoading = true) {
            isLoading && commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.payment_methods);
                const { deposits, withdraws } = res.data?.providers;
                const depositData = Object.entries(deposits?.data);
                const withdrawData = Object.entries(withdraws?.data);
                commit('SET_STATE', { name: 'depositProviders', value: depositData?.map(([key, value]) => ({ key, ...value })) ?? [] });
                commit('SET_STATE', { name: 'withdrawProviders', value: withdrawData?.map(([key, value]) => ({ key, ...value })) ?? [] });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async GET_STORE_DATA({ commit }, providerType = 'deposit') {
            commit('SET_STATE', { name: 'isLoading', value: true });
            const url = providerType === 'deposit' ? API_OLD.payment_methods_deposit_store_data : API_OLD.payment_methods_withdraw_store_data;
            try {
                const res = await ServicesOld.get(url);
                const { banks, fee_type } = res.data;
                commit('SET_STATE', { name: 'banks', value: banks ?? [] });
                commit('SET_STATE', { name: 'fee_type', value: fee_type ?? [] });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async STORE({ commit }, params) {
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const res = await ServicesOld.post(API_OLD.payment_methods_store, params);
                Vue.$toast.success(getSuccessServiceMsg(res));
                router.push({ name: 'PaymentMethodsManagement' })
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async GET_UPDATE_DATA({ commit }, id) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.payment_methods_update(id));
                const { banks, method } = res.data;
                commit('SET_STATE', { name: 'method', value: method ?? {} });
                commit('SET_STATE', { name: 'banks', value: banks ?? [] });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async UPDATE({ commit }, params) {
            const { id, formData } = params
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const res = await ServicesOld.patch(API_OLD.payment_methods_update(id), formData);
                Vue.$toast.success(getSuccessServiceMsg(res));
                router.push({ name: 'PaymentMethodsManagement' })
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async DESTROY({ dispatch }, id) {
            try {
                const res = await ServicesOld.delete(API_OLD.payment_methods_destroy(id));
                Vue.$toast.success(getSuccessServiceMsg(res));
                dispatch('GET_PROVIDERS', false);
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            }
        },
    },
    namespaced: true
}

export default paymentMethodsManagemet;