<template>
  <div class="d-flex flex-column flex-root h-100">
    <!--begin::Login-->
    <div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
      <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
           style="background-image: url(/assets/media/bg/bg-2.jpg);">
        <div class="login-form text-center text-white p-7 position-relative overflow-hidden">
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-15">
            <a href="#">
              <img src="/assets/media/logos/miracle-more-dark.png" class="max-h-75px" alt="" width="142" height="63"/>
            </a>
          </div>
          <!--end::Login Header-->
          <!--begin::Login Sign in form-->
          <div v-show="tab==='signin'" class="login-signin">
            <div class="mb-20">
              <h3 class="opacity-40 font-weight-normal">{{ $t('login-title') }}</h3>
              <p class="opacity-40">{{ $t('login-message-title') }}</p>
            </div>
            <form class="form" id="kt_login_signin_form" novalidate @keyup.enter="loginBtn">
              <div class="form-group">
                <div class="col-12 p-0">
                  <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                         type="email" v-model="login.email" :placeholder="$t('commons.e_mail')"/>
                </div>
              </div>
              <div class="form-group">
                <div class="col-12 p-0">
                  <i class="showPassword " @click="switchPasswordType()"
                     v-bind:class="passwordType === 'password' ? 'passwordEyeOff' : 'passwordEye'"></i>
                  <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                         :type="passwordType" v-model="login.password" :placeholder="$t('commons.password')"/>
                </div>
              </div>
              <div class="remember-and-forgot form-group d-flex flex-wrap justify-content-between align-items-center px-8 opacity-60">
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-outline checkbox-white text-white m-0">
                    <input type="checkbox" name="remember" :value="rememberMe" :checked="rememberMe" @input="toggleRemember">
                    <span></span>{{ $t('remember-me') }}</label>
                </div>
                <div @click="changeTab('forgot')" class="text-white font-weight-bold cursor-pointer">
                  {{ $t('forgot-password') }}
                </div>
              </div>
              <div class="form-group text-center mt-10">
                <div class="preloader btn btn-pill btn-primary px-15 py-3" v-if="isLoading">
                  <div class="spinner"></div>
                </div>
                <button v-else type="button" :disabled='showOtpModal' @click="loginBtn"
                        class="btn btn-pill btn-primary px-15 py-3">
                  {{ $t('sign_in_btn') }}
                </button>
              </div>
            </form>
          </div>

          <div v-show="tab==='forgot'" class="login-forgot">
            <div class="mb-20">
              <h3 class="opacity-40 font-weight-normal">{{ $t('forgot-password') }} ?</h3>
              <p class="opacity-40">{{ $t('forgot_passwd_desc') }}</p>
            </div>
            <form class="form fv-plugins-bootstrap fv-plugins-framework" id="kt_login_forgot_form">
              <div class="form-group mb-10 fv-plugins-icon-container">
                <input class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8" type="text"
                       placeholder="Email" name="email" autocomplete="off"
                       v-model="passwordMail.email"
                       @keyup.enter="forgotPassword"
                >
                <div class="fv-plugins-message-container"></div>
              </div>
              <div class="form-group">
                <button @click="changeTab('signin')" type="button" id="kt_login_forgot_cancel"
                        class="btn btn-pill btn-outline-white opacity-70 px-15 py-3 m-2">
                  {{ $t('commons.cancel') }}
                </button>
                <button id="kt_login_forgot_submit"
                        type="button"
                        class="btn btn-pill btn-primary opacity-90 px-15 py-3 m-2"
                        @click="forgotPassword"
                >
                  {{ $t('commons.submit') }}
                </button>
              </div>
              <div></div>
            </form>
          </div>

          <Language></Language>
          <!--end::Login Sign in form-->
        </div>
      </div>
    </div>
    <Modal/>
    <!--end::Login-->
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Modal from '@/components/login/Modal';
import Language from '@/components/layout/Language';
import Vue from "vue";

export default {
  name: 'Login',
  data() {
    return {
      passwordType: 'password',
      tab: 'signin'

    }
  },
  methods: {
    ...mapActions('customer', ['POST_LOGIN', 'POST_PASSWORD_MAIL']),
    loginBtn() {
      const email = this.login.email;
      const password = this.login.password;
      if (!this.validEmail(email)) {
        Vue.$toast.error(this.$t('custom_messages.invalid_field_format', {field: this.$t('commons.e_mail')}));
        return;
      }

      if (password === '') {
        Vue.$toast.error(this.$t('custom_messages.invalid_field_format', {field: this.$t('commons.password')}));
        return;
      }

      this.POST_LOGIN();
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    changeTab(tab) {
      this.tab = tab;
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    forgotPassword() {
      const email = this.passwordMail.email;
      if (!this.validEmail(email)) {
        Vue.$toast.error(this.$t('custom_messages.invalid_field_format', {field: 'email'}));
      }
      this.POST_PASSWORD_MAIL();
    },
    toggleRemember() {
      this.$store.commit('customer/toggleRememberMe');
    }
  },
  components: {
    Modal,
    Language
  },
  computed: {
    ...mapState({
      login: state => state.customer.login,
      passwordMail: state => state.customer.passwordMail,
      isLoading: state => state.customer.isLoading,
      showOtpModal: (state) => state.customer.showOtpModal,
      rememberMe: state => state.customer.rememberMe
    }),
  },
  created() {
    const savedLoginData = localStorage.getItem('_rememberLogin');
    if(savedLoginData != 'null') {
      this.$store.commit('customer/setRememberedEmail', savedLoginData)
      this.$store.commit('customer/toggleRememberMe', true);
    }
  }
}
</script>
<style lang="scss">


.login.login-5.login-forgot-on .login-forgot {
  display: block;
}

.login.login-5 .login-form {
  width: 100%;
  max-width: 450px;
}

@media (max-width: 575.98px) {
  .login.login-5 .login-form {
    width: 100%;
    max-width: 100%;
  }
}

.preloader {
  width: 140px;
  margin: 0 auto;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    margin-left: -15px;
  }
}
</style>
