import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';
import router from '../../router/index';

const spottradeManagement = {
    state: () => ({
        isLoading: false,
        isSubmitting: false,
        currencies: [],
    }),
    mutations: {
        SET_STATE(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {
        async GET_STORE_DATA({ commit }) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.spottrade_tp_get_store_data);
                commit('SET_STATE', { name: 'currencies', value: res.data?.currencies ?? [] });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async STORE({ commit }, params) {
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const res = await ServicesOld.post(API_OLD.spottrade_tp_store, params);
                Vue.$toast.success(getSuccessServiceMsg(res));
                router.push({ name: 'SpotTradeTPManagement' })
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async UPDATE_STATUS({ commit }, params) {
            const { id, status, status_type } = params
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            const urls = {
                "spot_status": API_OLD.spottrade_tp_update_spot_status(id),
                "margin_status": `spottrade/updateMarginStatus/${id}`,  // @TODO this will come from backend later
            };
            try {
                const res = await ServicesOld.post(urls[status_type], { [status_type]: status });
                Vue.$toast.success(getSuccessServiceMsg(res));
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
    },
    namespaced: true
}

export default spottradeManagement;