import axios from 'axios';
import i18n from '@/config/_i18n'
import router from '@/router';
const CancelToken = axios.CancelToken;
const AxiosTokenSource = CancelToken.source();

const BASE_API_URL = process.env.VUE_APP_CRM_API_URI;
const ServicesOld = axios.create({baseURL: String(BASE_API_URL), cancelToken: AxiosTokenSource.token});

let lang = sessionStorage.getItem('lang') || 'en';

ServicesOld.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        const auth = token ? `Bearer ${token}` : '';
        config.headers.common['Authorization'] = auth;
        config.headers.common['X-Localization'] = lang;
        return config;
    },
    error => Promise.reject(...error)
);

ServicesOld.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && error.response.data.message === "Expired JWT Token") {
        AxiosTokenSource.cancel('401 error');
        localStorage.removeItem('userToken');
        window.location = '/';
      }
      if (error.response.status === 401) {
        // window.location = '/no-permission';
        router.push({ name: 'NoPermission' }).catch(() => {});
      }
      
      if ([404, 405, 500].includes(error?.response?.status)) {
        let errorMessage = i18n.t('service.fatal_error');  
        throw {
          ...error,
          response: {
            ...error.response,
            data: {
              ...error.response.data,
              message: errorMessage
            }
          }
        };
      }

      throw error;
    }
);

export default ServicesOld;
