import Vue from "vue";
import API_OLD from "../../api/index_old";
import ServicesOld from "../../config/_axios_old";
import { getErrorServiceMsg, getSuccessServiceMsg } from "../../utils";
// import i18n from '../../config/_i18n'

const depositTransaction = {
  state: () => ({
    isLoading: false,
    deposit_data: [],
    audit_modal_visible: false,
    audits: [],
    detailsModalVisible: false,
    detailsModalData: {
      deposit: {},
      defaultCurrency: {},
    },
    detailsModalActionForm: {
      action: "",
      missing_amount: 0.0,
      missing_amount_desc: "",
      sms: "",
    },
    smsModalVisible: false,
    smsFinanceModalVisible: false,
    financeModalActionForm: {
      action: "",
      sms: "",
    },
  }),
  mutations: {
    SET_DEPOSIT_DATA(state, payload) {
      state.deposit_data = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_STATE(state, { field, value }) {
      state[field] = value;
    },
    TOGGLE_AUDIT_MODAL(state, payload) {
      state.audit_modal_visible = payload;
      if (!payload) {
        state.audits = [];
      }
    },
    TOGGLE_DETAILS_MODAL(state, payload) {
      state.detailsModalVisible = payload;
      if (!payload) {
        state.detailsModalData = {
          deposit: {},
          defaultCurrency: {},
        };
      }
    },
    TOGGLE_SMS_MODAL(state, payload) {
      state.smsModalVisible = payload;
    },
    TOGGLE_SMS_FINANCE_MODAL(state, payload) {
      state.smsFinanceModalVisible = payload;
    },
  },
  actions: {
    async GET_DEPOSIT_DATA({ commit }, { user }) {
      commit("SET_LOADING", true);
      try {
        const res = await ServicesOld.post(API_OLD.deposit_data, {
          user: user ?? "",
        });
        commit("SET_DEPOSIT_DATA", res.data.data);
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async GET_AUDITS({ commit }, id) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(API_OLD.finacial_management_audit, {
          id,
          model: "App\\Models\\Finance\\Deposit",
        });
        commit("SET_STATE", { field: "audits", value: res.data?.audits ?? [] });
        commit("TOGGLE_AUDIT_MODAL", true);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async GET_DETAILS_MODAL({ commit }, id) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.get(
          API_OLD.finacial_management_deposit_details_modal(id)
        );
        const { deposit, defaultCurrency } = res.data;
        commit("SET_STATE", {
          field: "detailsModalData",
          value: {
            deposit: deposit ?? {},
            defaultCurrency: defaultCurrency ?? {},
          },
        });
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async DETAILS_MODAL_SMS({ commit, state }, { id, action }) {
      //___ mock
      /* commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        console.log(id)
        commit('SET_STATE', {
          field: 'detailsModalActionForm', value: {
            ...state.detailsModalActionForm,
            action
          }
        })
        commit('TOGGLE_SMS_MODAL', true);

        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_deposit_details_modal_sms(id),
          {}
        );
        commit("SET_STATE", {
          field: "detailsModalActionForm",
          value: {
            ...state.detailsModalActionForm,
            action,
          },
        });
        commit("TOGGLE_SMS_MODAL", true);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async DETAILS_MODAL_ACTION({ commit, state, dispatch }) {
      //___ mock
      /* const id = state.detailsModalData.deposit.id;
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        console.log('DETAILS_MODAL_ACTION/id: ', id);
        console.log('DETAILS_MODAL_ACTION/form: ', state.detailsModalActionForm)
        
        Vue.$toast.success('success');
        dispatch('GET_DETAILS_MODAL', id)
        commit('TOGGLE_SMS_MODAL', false);

        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      const id = state.detailsModalData.deposit.id;
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_deposit_details_modal(id),
          state.detailsModalActionForm
        );
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch("GET_DETAILS_MODAL", id);
        commit("TOGGLE_DETAILS_MODAL", false);
        commit("TOGGLE_SMS_MODAL", false);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    /**
     *
     * @param {*} param0
     * @param {*} id
     * PENDING = 10;
     * PROCESSING = 20;
     * COMPLETED = 30;
     * CANCELLED = 40;
     * ERROR = 50;
     * FINANCEPENDING = 60;
     * APPROVE_PENDING = 70;
     */
    async UPDATE_STATUS({ commit }, { id, status }) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.put(
          API_OLD.finacial_management_deposit_update_status({ id, status }),
          {}
        );
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },

    async FINANCE_SMS({ commit, state }, { id, action }) {
      //___ mock
      /* commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        console.log(id)
        commit('SET_STATE', {
          field: 'financeModalActionForm', value: {
            ...state.financeModalActionForm,
            action
          }
        })
        commit('TOGGLE_SMS_FINANCE_MODAL', true);

        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_deposit_finance_sms(id),
          {}
        );
        commit("SET_STATE", {
          field: "financeModalActionForm",
          value: {
            ...state.financeModalActionForm,
            action,
          },
        });
        commit("TOGGLE_SMS_FINANCE_MODAL", true);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async FINANCE_ACTION({ commit, state, dispatch }) {
      //___ mock
      /* const id = state.detailsModalData.deposit.id;
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        console.log('FINANCE_ACTION/id: ', id);
        console.log('FINANCE_ACTION/form: ', state.financeModalActionForm)
        
        Vue.$toast.success('success');
        dispatch('GET_DETAILS_MODAL', id)
        commit('TOGGLE_SMS_FINANCE_MODAL', false);

        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      const id = state.detailsModalData.deposit.id;
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_deposit_finance_modal(id),
          state.financeModalActionForm
        );
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch("GET_DETAILS_MODAL", id);
        commit("TOGGLE_DETAILS_MODAL", true);
        commit("TOGGLE_SMS_FINANCE_MODAL", false);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
  },
  namespaced: true,
};

export default depositTransaction;
