import Vue from "vue";
import API_OLD from "../../api/index_old";
import ServicesOld from "../../config/_axios_old";
import { getErrorServiceMsg } from "../../utils";

const users = {
  state: () => ({
    isLoading: false,
    customer_data: [],
    auditModalVisible: false,
    audits: [],
  }),
  mutations: {
    SET_ALL_CUSTOMER(state, payload) {
      state.customer_data = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    TOGGLE_AUDIT_MODAL(state, payload) {
      state.auditModalVisible = payload;
      if (!payload) {
        state.audits = [];
      }
    },
    SET_STATE(state, payload) {
      state[payload.name] = payload.value;
    },
  },
  actions: {
    async GET_ALL_CUSTOMER({ commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await ServicesOld.post(API_OLD.customer_data);
        console.log(res.data);
        commit("SET_ALL_CUSTOMER", res.data.data);
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async GET_CUSTOMER_AUDIT({ commit }, id) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(API_OLD.customer_audit_modal, {
          id,
          model: "App\\Models\\User\\User",
        });
        console.log(res, "modalll");
        commit("SET_STATE", { name: "audits", value: res?.data?.audits ?? [] });
        commit("TOGGLE_AUDIT_MODAL", true);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async GET_QUICKSALE_AUDIT({ commit }, id) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(API_OLD.customer_audit_modal, {
          id,
          model: "App\\Models\\Finance\\QuickSale",
        });
        commit("SET_STATE", { name: "audits", value: res?.data?.audits ?? [] });
        commit("TOGGLE_AUDIT_MODAL", true);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
  },
  namespaced: true,
};

export default users;
