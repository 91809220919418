import Vue from "vue";
// import API from '@/api';
import API_OLD from "@/api/index_old";
//  import Services from '@/config/_axios';
import ServicesOld from "../../config/_axios_old";
import ServiceNew from "../../config/_axios_new";
import router from "../../router/index";
//import i18n from '@/config/_i18n'
import { getSuccessServiceMsg, getErrorServiceMsg } from "../../utils";

const customer = {
  state: () => ({
    isLogin:
      (localStorage.getItem("isLogin") && localStorage.getItem("userToken")) ||
      "",
    login: {
      email: "",
      password: "",
    },
    user: {},
    permissions: null,
    isLoading: false,
    otp: {
      code: "",
    },
    generateamladdress: {
      address: "",
      id: "",
    },
    googleAut: false,
    twoFaScreen: false,
    showOtpModal: false,
    passwordMail: {
      email: "",
    },
    passwordReset: {
      token: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    rememberMe: false,
    qrCode: {
      QR_Image: "",
      email: "",
      secret: "",
    },
    notifications: {
      data: [],
      isLoading: false,
      isFetching: false,
      page: 1,
      hasNext: false,
    },
    buttonLoading: false,
  }),
  mutations: {
    IS_BUTTON_DISABLED(state, payload) {
      state.buttonLoading = payload;
    },
    SET_LOGIN(state, payload) {
      localStorage.setItem("userToken", payload.access_token);
      localStorage.removeItem("isLogin");
      state.showOtpModal = true;
      // state.login = { email: '', password: '' };
      state.otp = { code: "" };
      state.googleAut = payload.googleAut;
      state.twoFaScreen = payload.googleAut;
    },
    SET_GENERATE_AML_ADDRESS(state, payload) {
      state.generateamladdress.address = payload.address;
      state.generateamladdress.id = payload.id;
    },
    SET_OTP_LOGIN(state, payload) {
      localStorage.setItem("userToken", payload.employeeToken);
      localStorage.setItem("isLogin", true);
      state.isLogin = true;
      state.rememberMe
        ? localStorage.setItem("_rememberLogin", state.login.email)
        : localStorage.setItem("_rememberLogin", null);
      state.login = { email: "", password: "" };
      state.showOtpModal = false;
      state.otp = { code: "" };
      router.push({ path: "/" });
      location.reload();
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_PERMISSION(state, payload) {
      state.permissions = payload;
    },
    SET_LOGOUT(state) {
      localStorage.removeItem("userToken");
      localStorage.removeItem("isLogin");
      state.isLogin = false;

      router.push({ path: "/login" });
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    closeModal(state, payload) {
      state.showOtpModal = payload;
      state.otp = { code: "" };
    },
    updateScreen(state, payload) {
      state.twoFaScreen = payload;
    },
    updateToken(state, payload) {
      state.passwordReset.token = payload;
    },
    toggleRememberMe(state, payload = null) {
      state.rememberMe = payload ? payload : !state.rememberMe;
    },
    setRememberedEmail(state, payload) {
      state.login.email = payload;
    },
    SET_QR_CODE(state, payload) {
      state.qrCode = payload;
    },
    SET_NOTIFICATIONS(state, payload) {
      if (
        payload &&
        payload.constructor === Object &&
        Object.keys(payload).length > 0
      ) {
        for (let key in payload) {
          state.notifications = {
            ...state.notifications,
            [key]: payload[key],
          };
        }
      }
    },
  },
  actions: {
    POST_LOGIN({ state, commit }) {
      commit("IS_LOADING", true);
      ServicesOld.post(API_OLD.login, state.login)
        .then((res) => {
          if (res.data.status == "command") {
            commit("SET_LOGIN", res.data);
            commit("IS_LOADING", false);
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          commit("IS_LOADING", false);
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_PASSWORD_MAIL({ state }) {
      ServicesOld.post(API_OLD.password_mail, state.passwordMail)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_PASSWORD_RESET({ state }) {
      ServicesOld.post(API_OLD.password_reset, state.passwordReset)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
          router.push({ name: "Login" });
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_LOGIN_OTP({ state, commit }) {
      ServicesOld.post(API_OLD.login_check, state.otp)
        .then((res) => {
          if (res.data.status == "ok") {
            commit("SET_OTP_LOGIN", res.data);
            commit("IS_LOADING", false);
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    GET_USER({ commit }) {
      return new Promise((resolve, reject) => {
        ServicesOld.get(API_OLD.me)
          .then((res) => {
            resolve(res);
            commit("SET_USER", res.data);
          })
          .catch((err) => {
            reject(err);
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      });
    },
    GENERATE_AML_ADDRESS({ state }, params) {
      let data = {
        adminId: state.user.id,
        userId: params.userId,
        address: params.address,
        symbol: params.symbol,
        network_slug: params.network_slug
      };
      const headers = {
        "X-BITDENX-KEY": process.env.VUE_APP_BDXAPI_KEY,
      };
      ServiceNew.post(API_OLD.generate_aml_list, data, { headers })
        .then((res) => {
          let payload = res.data.data;
          let aml_scores = payload.amlScore;

          var data = {
            id: params.id,
            type: 2,
            aml_score: aml_scores,
          };
          ServicesOld.post(API_OLD.generate_aml_scores, data)
            .then(() => {
              window.location.reload();
            })
            .catch((err) => {
              if (err?.response?.data?.message) {
                Vue.$toast.error(getErrorServiceMsg(err.response));
              }
            });
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    REJECT_ADDRESS({ state }, params) {
      let data = {
        userId: state.user.id,
        id: params.id,
        type: params.type,
      };
      ServicesOld.post(API_OLD.generate_aml_scores, data)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
      //
    },
    ACCEPT_ADDRESS({ state }, params) {
      let data = {
        userId: state.user.id,
        id: params.id,
        type: params.type,
      };
      ServicesOld.post(API_OLD.generate_aml_scores, data)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
      //
    },

    //kycstatus

    ACCPET_KYC_STATUS({ state }, params) {
      let data = {
        userId: state.user.id,
        id: params.id,
        status: params.status,
      };

      ServicesOld.post(API_OLD.update_levels, data)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
          window.location.reload();
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    REJECT_KYC_STATUS({ state }, params) {
      let data = {
        userId: state.user.id,
        id: params.id,
        status: params.status,
      };

      ServicesOld.post(API_OLD.update_levels, data)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
          window.location.reload();
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
      //
    },
    //endkycstatus

    APPROVE_CRYPTO_ADDRESS({ state, commit }, params) {
      commit("IS_BUTTON_DISABLED", true);
      let data = {
        adminId: state.user.id,
        userId: params.user_id,
        address: params.address,
        amount: params.amount,
        fee: params.fee,
        symbol: params.symbol,
        currency: params.currency,
        code: params.code,
        id: params.withdraw_id,
        asset_name: params.asset_name,
        tag: params.tag,
      };

      console.log(data);

      if (params.symbol.toLowerCase() == "eur") {
        ServicesOld.post(API_OLD.withdrawstatusupdate, { code: params.code, status: 20,crm:true })
          .then(() => {
            const headers = {
              "X-BITDENX-KEY": process.env.VUE_APP_BDXAPI_KEY,
            };
            ServiceNew.post(API_OLD.withdrawRequestSepa, data, { headers })
              .then(async () => {
                // var id = data.id;
                // var status = 20;
                Vue.$toast.success(
                  "Request sent to bitdenex, it will be completed asap"
                );
                window.location.reload();
              })
              .catch((err) => {
                if (err?.response?.data?.message) {
                  Vue.$toast.error(err.response.data.message);
                }
              });
          })
          .catch((err) => {
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      } else {
        const headers = {
          "X-BITDENX-KEY": process.env.VUE_APP_BDXAPI_KEY,
        };
        ServiceNew.post(API_OLD.withdrawRequest, data, { headers })
          .then(async () => {
            var id = data.id;
            var status = 20;
            const res = await ServicesOld.put(
              API_OLD.finacial_management_withdraw_update_status({
                id,
                status,
              }),
              {}
            );
            if (res?.status) {
              Vue.$toast.success(
                "Request sent to bitdenex, it will be completed asap"
              );
              window.location.reload();
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              Vue.$toast.error(err.response.data.message);
            }
          });
      }
    },
    GET_PERMISSION({ commit }) {
      ServicesOld.get(API_OLD.permission)
        .then((res) => {
          commit("SET_PERMISSION", res.data.permissions);
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },

    GET_LOGOUT({ commit }) {
      commit("SET_LOGOUT");
      commit("IS_LOADING", false);
    },
    POST_SEND_CODE() {
      ServicesOld.post(API_OLD.send_code)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    CREATE_TWO_FACTOR() {
      ServicesOld.post(API_OLD.create_two_factor)
        .then((res) => {
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_LOGIN_OTP_2FA({ state, commit }) {
      ServicesOld.post(API_OLD.login_2fa_check, state.otp)
        .then((res) => {
          if (res.data.status == "ok") {
            commit("SET_OTP_LOGIN", res.data);
            commit("IS_LOADING", false);
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    GET_2FA_QR_CODE({ commit }, params) {
      const secret = params.key1,
        email = params.key2,
        time = params.key3;
      ServicesOld.get(API_OLD.twoFactorQrCode({ secret, email, time }))
        .then((res) => {
          commit("SET_QR_CODE", res.data);
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    POST_2FA_QR_CODE_CHANGE({ state }) {
      ServicesOld.post(API_OLD.twoFactorChange, {
        val1: state.qrCode.secret,
        val2: state.qrCode.email,
      })
        .then((res) => {
          if (res.data.status == "ok") {
            Vue.$toast.success(getSuccessServiceMsg(res));
            router.push({ path: "/" });
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    async fetchNotifications({ commit, state }, page = 1) {
      commit("SET_NOTIFICATIONS", {
        isLoading: page === 1 ? true : false,
        isFetching: page > 1 ? true : false,
      });
      try {
        const res = await ServicesOld.get(
          `${API_OLD.notifications}?page=${page}`
        );
        const { meta, data, links } = res.data;
        const currentPage = meta?.current_page || 1;
        const hasNext = links?.next ? !!links?.next : false;
        commit("SET_NOTIFICATIONS", {
          data: page === 1 ? data : [...state.notifications.data, ...data],
          isLoading: false,
          isFetching: false,
          page: currentPage,
          hasNext,
        });
        return res;
      } catch (error) {
        commit("SET_NOTIFICATIONS", { isLoading: false, isFetching: false });
        throw error;
      }
    },
    async readNotifications() {
      try {
        await ServicesOld.post(`${API_OLD.notifications}/read`);
      } catch (error) {
        // console.log();
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.isLogin,
  },
  namespaced: true,
};

export default customer;
