const api_old = {
  login: "login-api",
  login_check: "login-api/check",
  generate_aml_scores: "user/generate-report",
  generate_aml_list: "/mi/generateAmlScore",
  withdrawRequest: "/mi/withdrawRequest",
  withdrawRequestSepa: "/mi/fiatWithdrawRequest",
  withdrawstatusupdate: "/finance/withdraw/update-status",
  login_2fa_check: "login-api/login2facheck",
  send_code: "sms/send-code",
  create_two_factor: "sms/2fa-form",
  transactions: "transactions",
  transactions_calculate: "transactions/calculate",
  create_transaction: "transactions/store",
  employees: "employees",
  employees_new: "employees/store",
  employees_new_get: "employees/create",
  employees_2fa: "employees/re-2fa/",
  roles: "authority/roles",
  roles_new_get: "authority/roles/create",
  institutions: "institutions",
  institutions_new_get: "institutions/create",
  institutions_new: "institutions/store",
  profile_security_settings: "profile/security_settings",
  change_password_send_sms: "change-password/send-sms",
  change_password_send_confirmation: "change-password/send-confirmation",
  user_search: "user/search",
  currencies: "/api/currencies",
  finance_currencies: "finance/common/currencies", // get currencies for deposit or withdraw transaction
  finance_balances: "finance/common/balances",
  finance_banks: "finance/common/banks",
  deposit_providers: "finance/deposit/providers",
  deposit_transaction_history: "finance/deposit/histories",
  deposit_create: "finance/deposit/create",
  deposit_approve: "finance/deposit/approved",
  deposit_cancel: "finance/deposit/cancel",
  deposit_assign: "finance/deposit/assign",
  withdraw_bank_transfer: "finance/withdraw/wallets/bank_transfer",
  withdraw_crypto_currency: "finance/withdraw/wallets/crypto_currency",
  withdraw_create: "finance/withdraw/create",
  withdraw_wallets_create: "finance/withdraw/wallets/create",
  withdraw_transaction_history: "finance/withdraw/histories",
  role_list: "authority/roles/data",
  permission: "authority/roles/employee-permissions",
  get_create_transaction_data: "transactions/create",
  transactions_data: "transactions/pending/data",
  transactions_datatable: "transactions/pending/datatable",
  transactions_datatable_data: "transactions/pending/datatable-data",
  employees_data: "employees/data",
  inst_data: "institutions/data",
  inst_datatable: "institutions/datatable",
  inst_datatable_data: "institutions/datatable-data",
  password_mail: "password/email",
  password_reset: "password/reset",
  employees_audit: "audit/modal",
  merchant_last: "merchant/last",
  crypto_create: "financecrm/crypto-transaction/create",
  crypto_store: "financecrm/crypto-transaction/store",
  deposit_data: "financecrm/deposit/data",
  deposit_datatable: "financecrm/deposit/datatable",
  deposit_datatable_data: "financecrm/deposit/datatable-data",
  withdraw_data: "financecrm/withdraw/data",
  withdraw_datatable: "financecrm/withdraw/datatable",
  withdraw_datatable_data: "financecrm/withdraw/datatable-data",
  fait_datatable_data: "financecrm/withdraw/fiatdeposit-datatable-data",
  customer_data: "user/data",
  update_levels: "/user/update-level",
  bankaddress: "user/bank-address",
  customer_datatable: "user/datatable",
  customer_datatable_data: "user/datatable-data",
  whitelistaddress: "user/whitelist-address",
  trading_data: "user/quicksalebuydata",
  trading_datatable: "user/quicksalebuydata/datatable",
  trading_datatable_data: "user/quicksalebuydata/datatable-data",
  attachment_data: "user/attachments/data",
  attachment_datatable: "user/attachments/datatable",
  attachment_datatable_data: "user/attachments/datatable-data",
  level_update_data: "level-request/data",
  level_update_datatable: "level-request/datatable",
  level_update_datatable_data: "level-request/datatable-data",
  permissions_list: "authority/permissions/data",
  permissions_datatable: "authority/permissions/datatable",
  permissions_datatable_data: "authority/permissions/datatable-data",
  permissions_store_data: "authority/permissions/create",
  permissions_store: "authority/permissions",
  permissions_update_data: (id) => `authority/permissions/${id}/edit`,
  permissions_update: (id) => `authority/permissions/${id}`,
  permissions_delete: function (id) {
    return this.permissions_update(id);
  },
  financecrm_currencies: "financecrm/currencies",
  financecrm_currencies_edit: (id) => `financecrm/currencies/${id}/edit`,
  financecrm_currencies_balance_activate: (id) =>
    `financecrm/currencies/${id}/balance/active`,
  financecrm_currencies_balance_make_passive: (id) =>
    `financecrm/currencies/${id}/balance/passive`,
  financecrm_currencies_rates: "financecrm/currencies/rates",
  bank_management_list: "bank/data",
  bank_management_datatable: "bank/datatable",
  bank_management_datatable_data: "bank/datatable-data",
  bank_management_store_data: "bank/create",
  bank_management_store: "bank/store",
  bank_management_update: (id) => `bank/${id}/edit`,
  bank_management_destroy: (id) => `bank/${id}`,
  payment_methods: "providers/payments",
  payment_methods_deposit_store_data: "providers/payments/create/deposit",
  payment_methods_withdraw_store_data: "providers/payments/create/withdraw",
  payment_methods_store: "providers/payments/store",
  payment_methods_update: (id) => `providers/payments/${id}/edit`,
  payment_methods_destroy: (id) => `providers/payments/${id}`,
  ip_list: "ip/data",
  ip_new: "ip/new",
  ip_update: (id) => `ip/${id}/update`,
  ip_delete: (id) => `ip/${id}/delete`,
  customer_audit_modal: "audit/modal",
  markets_management_list: "markets",
  user_quick_search: (fullname) => `user/quicksearchajax?fullname=${fullname}`,
  user_details: (id) => `user/${id}`,
  user_details_edit_user_info: (id) => `user/${id}/edit`,
  user_details_update_user_info: (id) => `user/${id}/update`,
  user_details_balances: (id) => `user/${id}/balances`,
  user_details_balance_edit: (id) => `user/${id}/balances/edit`,
  user_details_external_balances: (id) => `user/${id}/external-balances/data`,
  user_details_external_balances_create: (id) =>
    `user/${id}/external-balances/create`,
  user_details_external_balances_store: (id) =>
    `user/${id}/external-balances/store`,
  user_details_external_balances_edit: ({ user_id, balance_id }) =>
    `user/${user_id}/external-balances/${balance_id}/edit`,
  user_details_external_balances_delete: ({ user_id, balance_id }) =>
    `user/${user_id}/external-balances/${balance_id}`,
  user_details_attachments: (id) => `user/${id}/attachments/data`,
  user_details_notification_options: (id) => `user/${id}/notifications`,
  user_details_financial_transaction_history: (id) =>
    `user/${id}/transactions/data`,
  user_details_spot_history: (id) =>
    `user/${id}/transactions/spotData`,
  user_details_transaction_history: (id) => `user/${id}/history/data`,
  user_details_agent_transaction_history: (id) =>
    `user/${id}/history/employees/data`,
  failed_trading_data: "user/failedtransaction",
  level_request_details_modal: (id) => `level-request/${id}/modal`,
  level_request_sms: (id) => `level-request/${id}/sms`,
  level_request_attachment_confirmation: (id) => `attachment/${id}/confirm`,
  finacial_management_audit: "audit/modal",
  finacial_management_deposit_details_modal: (id) =>
    `financecrm/deposit/${id}/modal`,
  finacial_management_deposit_details_modal_sms: (id) =>
    `financecrm/deposit/${id}/sms`,
  finacial_management_deposit_finance_modal: (id) =>
    `financecrm/deposit/${id}/finance-modal`,
  finacial_management_deposit_finance_sms: (id) =>
    `financecrm/deposit/${id}/finance-sms`,
  finacial_management_deposit_update_status: ({ id, status }) =>
    `financecrm/deposit/${id}/update-status/${status}`,
  finacial_management_withdraw_details_modal: (id) =>
    `financecrm/withdraw/${id}/modal`,
  finacial_management_withdraw_details_modal_sms: (id) =>
    `financecrm/withdraw/${id}/sms`,
  finacial_management_withdraw_finance_modal: (id) =>
    `financecrm/withdraw/${id}/finance-modal`,
  finacial_management_withdraw_finance_sms: (id) =>
    `financecrm/withdraw/${id}/finance-sms`,
  finacial_management_withdraw_update_status: ({ id, status }) =>
    `financecrm/withdraw/${id}/update-status/${status}`,
  trading_buyfromoutpurchase_create: (id) =>
    `financecrm/out-purchases/one/create/quicksales/${id}`,
  trading_buyfromoutpurchase_cryptobanks_create: (id) =>
    `financecrm/out-purchases/one/create/cryptobanks/${id}`,
  trading_buyfromoutpurchase_store: "financecrm/out-purchases/one/create/",
  merchant_currencies: "merchant/currencies",
  me: "me",
  totalByCurrencyForUserBalances:
    "dashboard/total-by-currency-for-user-balances",
  twoFactorQrCode: ({ secret, email, time }) =>
    `2fa/qr-code/${secret}/${email}/${time}`,
  twoFactorChange: "2fa/change",
  institutionsGetDistributor: "institutions/get-distributor",
  institutionsGetDistributorMerchants: "institutions/get-distributor-merchants",
  institutionsGetMerchantBranches: "institutions/get-merchant-branches",
  spottrade_tp_datatable: "spottrade/datatable",
  spottrade_tp_datatable_data: "spottrade/datatable-data",
  spottrade_tp_get_store_data: "spottrade/create",
  spottrade_tp_store: "spottrade/store",
  spottrade_tp_update_spot_status: (id) => `spottrade/updateStatus/${id}`,
  user_details_update_to_vip: (userId) => `user/${userId}/updateToVip`,
  create_branch_deposit_transaction: "transactions/deposit/store",
  merchant_deposits_datatable: "transactions/deposit/datatable",
  merchant_deposits_datatable_data: "transactions/deposit/datatableData",
  notifications: "notifications",
  employee_referral: (employeeID) => `employees/referral-link/${employeeID}`,
  user_details_attachment_add: "user/attachments/add",
  user_details_attachment_delete: (attachmentId) => `user/attachments/${attachmentId}`,
  resend_verification_email: (customerId) => `user/resend-verification-email/${customerId}`,
};

export default api_old;
