import Vue from 'vue';
import ServicesOld from "@/config/_axios_old";
import API_OLD from '@/api/index_old';
//import i18n from '@/config/_i18n'
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';

const withdraw = {
    state: () => ({
        providersLoading: false,
        providers: [],
        historyLoading: false,
        historyData: [],
        historyLinks: {},
        historyMeta: {},
    }),
    mutations: {
        IS_LOADING(state, payload) {
            state[payload.type] = payload.value;
        },
        SET_PROVIDERS(state, payload) {
            state.providers = payload;
        },
        SET_HISTORY(state, payload) {
            state.historyData = payload.page === 1 ? payload.historyData : [...state.historyData, ...payload.historyData];
            state.historyLinks = payload.historyLinks;
            state.historyMeta = payload.historyMeta;
        },
    },
    actions: {
        async GET_PROVIDERS({ commit }, { isCrypto = false, currencyCode = "try" }) {
            commit('IS_LOADING', { type: 'providersLoading', value: true });
            const url = isCrypto ? API_OLD.withdraw_crypto_currency : API_OLD.withdraw_bank_transfer;
            try {
                const res = await ServicesOld.get(`${url}/${currencyCode}`)
                commit("SET_PROVIDERS", res?.data?.data?.data || [])
            } catch (err) {
                console.log('store/modules/deposit@GET_PROVIDERS error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
                commit('IS_LOADING', { type: 'providersLoading', value: false });
            }
        },
        async CREATE_WITHDRAW({ dispatch }, {fData, currencyCode}) {
            try {
                const res = await ServicesOld.post(`${API_OLD.withdraw_create}`, fData);
                dispatch('GET_TRANSACTIONHISTORY', { currencyCode, page: 1 })
                // console.log('CREATE_WITHDRAW', res.data);
                Vue.$toast.success(getSuccessServiceMsg(res?.data));
            } catch (err) {
                console.log('store/modules/deposit@CREATE_DEPOSIT error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            }
        },
        async CREATE_WALLET(_, payload) {
            try {
                const res = await ServicesOld.post(`${API_OLD.withdraw_wallets_create}`, payload.formData);
                Vue.$toast.success(getSuccessServiceMsg(res?.data));
            } catch (err) {
                console.log('store/modules/deposit@CREATE_DEPOSIT error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            }
        },
        async GET_TRANSACTIONHISTORY({ commit }, { currencyCode, page = 1 }) {
            commit('IS_LOADING', { type: 'historyLoading', value: true });
            try {
              const res = await ServicesOld.get(`${API_OLD.withdraw_transaction_history}/${currencyCode}?page=${page}`)
              // console.log('GET_TRANSACTIONHISTORY', res.data?.data);
              const payload = { 
                historyData: res?.data?.data?.data || [],
                historyLinks: res?.data?.data?.links || {},
                historyMeta: res?.data?.data?.meta || {},
                page
              }
              commit('SET_HISTORY', payload)
            } catch (err) {
                console.log('store/modules/deposit@GET_TRANSACTIONHISTORY error: ', err)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
              commit('IS_LOADING', { type: 'historyLoading', value: false });
            }
          },
    },

    namespaced: true
}

export default withdraw;
