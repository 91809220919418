import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';
import router from '../../router/index';


const ips = {
  state: () => ({
    isLoading: false,
    ips: [],
    isSubmitting: false,
    employees: [],
    ip: {}
  }),
  mutations: {
    SET_STATE(state, payload) {
      const { name, value } = payload;
      state[name] = value;
    },
  },
  actions: {
    async GET_IPS({ commit }, isLoading = true) {
      isLoading && commit('SET_STATE', { name: 'isLoading', value: true });
      try {
        const res = await ServicesOld.post(API_OLD.ip_list);
        commit('SET_STATE', { name: 'ips', value: res.data?.data ?? [] });
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
      } finally {
        commit('SET_STATE', { name: 'isLoading', value: false });
      }
    },
    async GET_NEW({ commit }) {
      commit('SET_STATE', { name: 'isLoading', value: true });
      try {
        const res = await ServicesOld.get(API_OLD.ip_new);
        let employees = [];
        if (res.data?.employees.length > 0) {
          employees = res.data.employees.map(val => ({ full_name: `${val.name} ${val.surname}`, ...val }))
        }
        commit('SET_STATE', { name: 'employees', value: employees });
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { name: 'isLoading', value: false });
      }
    },
    async POST_NEW({ commit }, params) {
      commit('SET_STATE', { name: 'isSubmitting', value: true });
      try {
        const res = await ServicesOld.post(API_OLD.ip_new, params);
        Vue.$toast.success(getSuccessServiceMsg(res));
        router.push({ name: 'Ips' })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { name: 'isSubmitting', value: false });
      }
    },
    async GET_UPDATE_DATA({ commit }, id) {
      commit('SET_STATE', { name: 'isLoading', value: true });
      try {
        const res = await ServicesOld.get(API_OLD.ip_update(id));
        let employees = [];
        if (res.data?.employees.length > 0) {
          employees = res.data.employees.map(val => ({ full_name: `${val.name} ${val.surname}`, ...val }))
        }
        commit('SET_STATE', { name: 'employees', value: employees });
        commit('SET_STATE', { name: 'ip', value: res.data?.ip ?? {} });
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { name: 'isLoading', value: false });
      }
    },
    async UPDATE_IP({ commit }, params) {
      commit('SET_STATE', { name: 'isSubmitting', value: true });
      try {
        const { id, formData } = params
        const res = await ServicesOld.post(API_OLD.ip_update(id), formData);
        Vue.$toast.success(getSuccessServiceMsg(res));
        router.push({ name: 'Ips' })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { name: 'isSubmitting', value: false });
      }
    },
    async DELETE_IP({ dispatch }, id) {
      try {
        const res = await ServicesOld.get(API_OLD.ip_delete(id));
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('GET_IPS', false);
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      }
    },
  },
  namespaced: true
}

export default ips;