import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import API_OLD from '@/api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';
import router from '../../router';

const roles = {
  state: () => ({
    roles: [],
    isLoading: true,
    roles_new_get: [],
    roleList: []
  }),
  mutations: {
    SET_ROLES(state, payload) {
      state.roles = payload;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_ROLES_NEW(state, payload) {
      state.roles_new_get = payload;
    },
    SET_ROLE_LIST(state, payload) {
      state.roleList = payload;
      console.log(state.roleList)
  },
  },
  actions: {
    GET_ROLES({ commit }, params) {
      Services.get(`${API.roles}?_dt=dt&_init=true&start=${params.start}&length=${params.length}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(res => {
          console.log("roles: ", res.data.data)
          commit('SET_ROLES', res.data.data);
        })
        .catch(err => {
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    GET_NEW_ROLES({ commit }) {
      return new Promise((resolve, reject) => {
        commit('IS_LOADING', true);
        ServicesOld.get(`${API_OLD.roles_new_get}`)
          .then(res => {
            // console.log("roles GET: ", res.data)
            commit('SET_ROLES_NEW', res.data);
            resolve('success')
          })
          .catch(err => {
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
            reject('error')
          })
          .finally(() => {
            commit('IS_LOADING', false);
          })
      })
    },
    POST_ROLES({ commit }, params) {
      ServicesOld.post(`${API_OLD.roles}`, params)
        .then(res => {
          console.log("post: ", res.data)
          commit('IS_LOADING', false);
          Vue.$toast.success(getSuccessServiceMsg(res));
          router.push({ name: "RoleList" });
        })
        .catch(err => {
          console.log("err: ", err)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    EDIT_ROLES({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('IS_LOADING', true);
        ServicesOld.get(`${API_OLD.roles}/${id}/edit`)
          .then(res => {
            commit('SET_ROLES_NEW', res.data);
            resolve('success')
          })
          .catch(err => {
            reject('error')
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          })
          .finally(() => {
            commit('IS_LOADING', false);
          })
      })
    },
    UPDATE_ROLES({ commit }, params) {
      ServicesOld.patch(`${API_OLD.roles}/${params.id}`, params.data)
        .then(res => {
          console.log("post: ", res.data)
          commit('IS_LOADING', false);
          Vue.$toast.success(getSuccessServiceMsg(res));
          router.push({ name: "RoleList" });
        })
        .catch(err => {
          console.log("err: ", err)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    DELETE_ROLES({ commit, dispatch }, id) {
      ServicesOld.delete(`${API_OLD.roles}/${id}`)
        .then(res => {
          console.log("roles delete: ", res.data)
          commit('IS_LOADING', false);
          Vue.$toast.success(getSuccessServiceMsg(res));
          dispatch("GET_ROLE_LIST")
        })
        .catch(err => {
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    async GET_ROLE_LIST({ commit }) {
      commit('IS_LOADING', true);
      await ServicesOld.post(API_OLD.role_list)
        .then(res => {
          console.log("GET_ROLE_LIST: ",res.data)
          commit('IS_LOADING', false);
          commit('SET_ROLE_LIST', res.data.data);
        })
        .catch(err => {
          console.log("err: ",err)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
  },
  namespaced: true
}

export default roles;