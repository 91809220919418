import Vue from 'vue';
import VueI18n from 'vue-i18n';
import tr from './i18n-translations/tr.json'
import en from './i18n-translations/en.json'
import { localeChanged } from 'vee-validate';
import _en from "vee-validate/dist/locale/en.json";
import _tr from "vee-validate/dist/locale/tr.json";

Vue.use(VueI18n);
const qs = new URLSearchParams(window.location.search);

if (qs.has('lang')) {
  sessionStorage.setItem('lang', qs.get('lang'));
}

let lang = sessionStorage.getItem('lang');
if (!lang) {
  const detectLang = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
  if (detectLang === 'tr' || detectLang === 'tr-TR') {
    lang = 'tr';
  } else if (detectLang === 'nl' || detectLang === 'nl-NL') {
    lang = 'nl';
  } else if (detectLang === 'me' || detectLang === 'me-ME') {
    lang = 'me';
  } else if (detectLang === 'et' || detectLang === 'et-ET') {
    lang = 'et';
  } else if (detectLang === 'de' || detectLang === 'de-DE') {
    lang = 'de';
  } else {
    lang = 'en';
  }
  sessionStorage.setItem('lang', lang);
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: {
    en: { ...en, validation: _en.messages },
    tr: { ...tr, validation: _tr.messages }
  }

});

export function changeLanguages(lang, refresh) {
  sessionStorage.setItem('lang', lang);
  i18n.locale = lang;
  localeChanged();
  if (refresh) {
    window.history.pushState({}, document.title, window.location.pathname);
    location.reload();
  }
}

export default i18n;