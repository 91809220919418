import Vue from "vue";
import API_OLD from "@/api/index_old";
import ServicesOld from "../../config/_axios_old";
import { getErrorServiceMsg, getSuccessServiceMsg } from "../../utils";
// import i18n from '../../config/_i18n'

const withdrawTransaction = {
  state: () => ({
    isLoading: false,
    withdraw_data: [],
    audit_modal_visible: false,
    audits: [],
    detailsModalVisible: false,
    detailsModalData: {
      withdraw: {},
      defaultCurrency: {},
    },
    smsModalVisible: false,
    smsFinanceModalVisible: false,
    financeModalActionForm: {
      action: "",
      sms: "",
      txid: "",
      confirmType: "",
    },
  }),
  mutations: {
    SET_WITHDRAW_DATA(state, payload) {
      state.withdraw_data = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_STATE(state, { field, value }) {
      state[field] = value;
    },
    TOGGLE_AUDIT_MODAL(state, payload) {
      state.audit_modal_visible = payload;
      if (!payload) {
        state.audits = [];
      }
    },
    TOGGLE_DETAILS_MODAL(state, payload) {
      state.detailsModalVisible = payload;
      if (!payload) {
        state.detailsModalData = {
          withdraw: {},
          defaultCurrency: {},
        };
      }
    },
    TOGGLE_SMS_MODAL(state, payload) {
      state.smsModalVisible = payload;
    },
    TOGGLE_SMS_FINANCE_MODAL(state, payload) {
      state.smsFinanceModalVisible = payload;
    },
  },
  actions: {
    async GET_WITHDRAW_DATA({ commit }, { user }) {
      commit("SET_LOADING", true);
      try {
        const res = await ServicesOld.post(API_OLD.withdraw_data, {
          user: user ?? "",
        });
        commit("SET_WITHDRAW_DATA", res.data.data);
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async GET_AUDITS({ commit }, id) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(API_OLD.finacial_management_audit, {
          id,
          model: "App\\Models\\Finance\\Withdraw",
        });
        commit("SET_STATE", { field: "audits", value: res.data?.audits ?? [] });
        commit("TOGGLE_AUDIT_MODAL", true);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async GET_DETAILS_MODAL({ commit }, id) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.get(
          API_OLD.finacial_management_withdraw_details_modal(id)
        );
        const { withdraw, defaultCurrency } = res.data;
        commit("SET_STATE", {
          field: "detailsModalData",
          value: {
            withdraw: withdraw ?? {},
            defaultCurrency: defaultCurrency ?? {},
          },
        });
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async DETAILS_MODAL_SMS({ commit, state }, { id, action }) {
      // __ mock
      /* commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        console.log(id);
        commit('SET_STATE', {
          field: 'financeModalActionForm', value: {
            ...state.financeModalActionForm,
            action
          }
        })
        commit('TOGGLE_SMS_MODAL', true);
        Vue.$toast.success(getSuccessServiceMsg(res));
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_withdraw_details_modal_sms(id),
          {}
        );
        commit("SET_STATE", {
          field: "financeModalActionForm",
          value: {
            ...state.financeModalActionForm,
            action,
          },
        });
        commit("TOGGLE_SMS_MODAL", true);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async DETAILS_MODAL_ACTION({ commit, state, dispatch }) {
      // __ mock
      /* const id = state.detailsModalData.withdraw.id;
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      // eslint-disable-next-line no-unused-vars
      const { action, txid, sms } = state.financeModalActionForm;
      setTimeout(() => {
        console.log({ action, txid, sms });
        Vue.$toast.success('success');
        dispatch('GET_DETAILS_MODAL', id)
        commit('TOGGLE_SMS_MODAL', false);
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      const id = state.detailsModalData.withdraw.id;
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      // eslint-disable-next-line no-unused-vars
      const { action, txid, sms } = state.financeModalActionForm;
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_withdraw_details_modal(id),
          { action, txid, sms }
        );
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch("GET_DETAILS_MODAL", id);
        commit("TOGGLE_DETAILS_MODAL", true);
        commit("TOGGLE_SMS_MODAL", false);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} id
     * PENDING = 10;
     * PROCESSING = 20;
     * COMPLETED = 30;
     * CANCELLED = 40;
     * ERROR = 50;
     * FINANCEPENDING = 60;
     */
    async UPDATE_STATUS({ commit }, { id, status }) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.put(
          API_OLD.finacial_management_withdraw_update_status({ id, status }),
          {}
        );
        if (res?.status) {
          var amount =
            parseFloat(res.data.data.amount) +
            parseFloat(res.data.data.fee_rate);
          var coin = res.data.data.currency_code;
          if (status == 40) {
            let data = {
              amount: amount,
              coin: coin,
              type: "deposit",
            };
            const headers = {
              "X-BITDENX-KEY": process.env.VUE_APP_BDXAPI_KEY,
            };
            ServicesOld.post(
              process.env.VUE_APP_BDXAPI_URI + "/mi/updateWallet",
              data,
              { headers }
            )
              .then(() => {
                Vue.$toast.success(getSuccessServiceMsg(res));
                return res;
              })
              .catch((err) => {
                if (err?.response?.data?.message) {
                  Vue.$toast.error(err.response.data.message);
                }
              });
          } else if (status == 10) {
            let data = {
              amount: amount,
              coin: coin,
              type: "withdraw",
            };
            const headers = {
              "X-BITDENX-KEY": process.env.VUE_APP_BDXAPI_KEY,
            };
            ServicesOld.post(
              process.env.VUE_APP_BDXAPI_URI + "/mi/updateWallet",
              data,
              { headers }
            )
              .then(() => {
                Vue.$toast.success(getSuccessServiceMsg(res));
                return res;
              })
              .catch((err) => {
                if (err?.response?.data?.message) {
                  Vue.$toast.error(err.response.data.message);
                }
              });
          }
        }
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },

    async FINANCE_SMS({ commit, state }, { id, action, confirmType }) {
      //___ mock
      /* commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        console.log(id)
        commit('SET_STATE', {
          field: 'financeModalActionForm', value: {
            ...state.financeModalActionForm,
            action,
            confirmType
          }
        })
        commit('TOGGLE_SMS_FINANCE_MODAL', true);

        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_withdraw_finance_sms(id),
          {}
        );
        commit("SET_STATE", {
          field: "financeModalActionForm",
          value: {
            ...state.financeModalActionForm,
            action,
            confirmType,
          },
        });
        commit("TOGGLE_SMS_FINANCE_MODAL", true);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
    async FINANCE_ACTION({ commit, state, dispatch }) {
      //___ mock
      /* const id = state.detailsModalData.withdraw.id;
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      setTimeout(() => {
        if(+state.detailsModalData.withdraw?.status === 60) { // if pending financacial approval txid is null
          state.financeModalActionForm.txid = '';
        }
        console.log('FINANCE_ACTION/id: ', id);
        console.log('FINANCE_ACTION/form: ', state.financeModalActionForm)
        
        Vue.$toast.success('success');
        dispatch('GET_DETAILS_MODAL', id)
        commit('TOGGLE_SMS_FINANCE_MODAL', false);

        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }, 700) */

      const id = state.detailsModalData.withdraw.id;
      if (+state.detailsModalData.withdraw?.status === 60) {
        // if pending financacial approval txid is null
        state.financeModalActionForm.txid = "";
      }
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post(
          API_OLD.finacial_management_withdraw_finance_modal(id),
          state.financeModalActionForm
        );
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch("GET_DETAILS_MODAL", id);
        commit("TOGGLE_DETAILS_MODAL", true);
        commit("TOGGLE_SMS_FINANCE_MODAL", false);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
  },
  namespaced: true,
};

export default withdrawTransaction;
