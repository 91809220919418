import Vue from "vue";
import ServicesOld from "../../config/_axios_old";
import API_OLD from "../../api/index_old";
import { getErrorServiceMsg } from "../../utils";

const userquicksearch = {
  state: () => ({
    searching: false,
    data: [],
  }),
  mutations: {
    SET_STATE(state, payload) {
      state[payload.field] = payload.value;
    },
  },
  actions: {
    async quicksearchAction({ commit }, fullname) {
      if (!fullname) {
        commit("SET_STATE", { field: "data", value: [] });
        return;
      }
      commit("SET_STATE", { field: "searching", value: true });
      try {
        const res = await ServicesOld.get(API_OLD.user_quick_search(fullname));
        commit("SET_STATE", { field: "data", value: res.data });
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit("SET_STATE", { field: "searching", value: false });
      }
    },
  },
  namespaced: true,
};

export default userquicksearch;
