import Vue from 'vue';
import API_OLD from '@/api/index_old';
import ServicesOld from "../../config/_axios_old";
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';


const cryptoTransaction = {
  state: () => ({
    isLoading: false,
    currencies: [],
    crypto: {
      selectedCoin: {},
      tx_id: ""
    }
  }),
  mutations: {
    SET_CRYPTO_CREATE(state, payload) {
      state.currencies = payload;
      if(payload.length > 0) {
        state.crypto.selectedCoin = payload[0]
      }
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },
  actions: {  
    async GET_CRYPTO_CREATE({ commit }) {
      commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.get(API_OLD.crypto_create)
        commit('SET_CRYPTO_CREATE', res.data?.currencies);
      } catch (err) {
        if(err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async POST_CRYPTO_CREATE({ state, commit }) {
      commit('SET_LOADING', true);
      try {
        const res = await ServicesOld.post(`${API_OLD.crypto_store}?currency=${state.crypto.selectedCoin.code}&txid=${state.crypto.tx_id}`);
        if(res.data?.status == 'ok') Vue.$toast.success(getSuccessServiceMsg(res));
      } catch (err) {
        if(err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('SET_LOADING', false);
      }
    },    
  },
  namespaced: true
}

export default cryptoTransaction;