<template>
  <div class="modal fade" :class="{ show: showOtpModal }">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ $t('otp_code') }}</h5>
          <button type="button" class="close" @click='closeModal()'>
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <form class="form">
            <div v-if="twoFaScreen==false">
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto rounded-pill py-4 px-8"
                    type="tel" v-mask="'######'"
                    v-on:keydown.enter.prevent=''
                    v-model="otp.code"
                    ref="otpCode"
                    :placeholder="$t('otp_code')"
                    autocomplete="off"/>
              </div>
              <div class="form-group text-center mt-10">
                <button type="button" class="btn btn-primary font-weight-bold mx-2" @click="loginOtpBtn">
                  {{ $t('sign_in_btn') }}
                </button>
                <button type="button" class="btn btn-light-primary font-weight-bold mx-2" @click="sendCode">
                  {{ $t('send_sms_again') }}
                </button>
              </div>
              <div class="form-group text-center">
                <button type="button" class="btn btn-link-primary font-weight-bold" @click="create2Fa">
                  {{ $t('sign_in_with_google_auth') }}
                </button>
              </div>
            </div>
            <div v-else>
              <div class="form-group">
                <input class="form-control form-control-solid h-auto rounded-pill py-4 px-8" type="tel"
                       v-mask="'######'" v-on:keydown.enter.prevent='' v-model="otp.code" ref="otpCode"
                       placeholder="Google Authenticator Code" autocomplete="off"/>
              </div>
              <div class="form-group text-center mt-10">
                <button type="button" class="btn btn-primary font-weight-bold mx-2" @click="login2FaOtpBtn">
                  {{ $t('sign_in_btn') }}
                </button>
                <button type="button" class="btn btn-light-primary font-weight-bold mx-2" @click="smsScreen">
                  {{ $t('sign_in_with_sms') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: 'Modal',
  computed: {
    ...mapState({
      otp: state => state.customer.otp,
      code: state => state.customer.otp.code,
      showOtpModal: state => state.customer.showOtpModal,
      googleAuthenticator: state => state.customer.googleAut
    }),
    twoFaScreen: {
      get() {
        return this.$store.state.customer.twoFaScreen;
      },
      set(value) {
        this.$store.commit('customer/updateScreen', value);
      }
    },
  },
  methods: {
    ...mapActions('customer', ['POST_LOGIN_OTP', 'POST_SEND_CODE', 'CREATE_TWO_FACTOR', 'POST_LOGIN_OTP_2FA']),
    loginOtpBtn() {
      this.POST_LOGIN_OTP();
    },
    closeModal() {
      this.$store.commit('customer/closeModal', false);
    },
    sendCode() {
      console.log("tekrar gönder")
      this.POST_SEND_CODE();
    },
    smsScreen() {
      this.twoFaScreen = false;
      this.$refs.otpCode.focus();
      this.POST_SEND_CODE();
    },
    create2Fa() {
      console.log("goog.:", this.googleAuthenticator)
      if (this.googleAuthenticator == false) this.CREATE_TWO_FACTOR();
      else this.twoFaScreen = true;
    },
    login2FaOtpBtn() {
      this.POST_LOGIN_OTP_2FA();
    }
  },

  watch: {
    showOtpModal(val) {
      if (val) {
        this.$nextTick(() => this.$refs.otpCode.focus())
      }
    },
    code(value) {
      setTimeout(() => {
        if (value && value.replace(/\s+/g, '').length >= 6) {
          if (!this.twoFaScreen) {
            this.loginOtpBtn()
            return;
          }
          this.login2FaOtpBtn();
        }
      }, 300)
    }
  }
}
</script>

<style scoped>
.show {
  display: block;
}
</style>
