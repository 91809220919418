import Vue from 'vue';
import ServicesOld from "@/config/_axios_old";
import API_OLD from '@/api/index_old';
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg } from '../../utils';

const finance = {
    state: () => ({
        currenciesLoading: false,
        currencies: [],
        cryptos: [],
        banksLoading: false,
        banks: [],
        balancesLoading: false,
        balances: [],
        balanceByCurrency: 0,

    }),
    mutations: {
        SET_DATA(state, payload) {
            state[payload.type] = payload.data;
        },
        IS_LOADING(state, payload) {
            state[payload.type] = payload.value;
        },
    },
    actions: {
        async GET_COMMON_CURRENCIES({ commit }, { kind = "deposit" }) {
            commit('IS_LOADING', { type: 'currenciesLoading', value: true });
            try {
                const res = await ServicesOld.get(`${API_OLD.finance_currencies}`)
                const currencies = [], cryptos = [];
                const data = res?.data?.data?.data;
                Object.keys(data).length > 0 && Object.keys(data).map((curr) => {
                    if (data[curr][kind]) { // if withdraw: true or deposit: true
                        currencies.push({
                            ...data[curr],
                            name: data[curr].name + "(" + data[curr].code + ")",
                            value: data[curr].code
                        })
                        if (data[curr].crypto) {
                            cryptos.push({
                                ...data[curr],
                                name: data[curr].name + "(" + data[curr].code + ")",
                                value: data[curr].code
                            })
                        }
                    }
                })
                commit('SET_DATA', { type: 'currencies', data: currencies });
                commit('SET_DATA', { type: 'cryptos', data: cryptos });
            } catch (err) {
                console.log('GET_FINANCE_CURRENCIES error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
                commit('IS_LOADING', { type: 'currenciesLoading', value: false });
            }
        },
        async GET_BALANCES({ commit }) {
            commit('IS_LOADING', { type: 'balancesLoading', value: true });
            try {
                const res = await ServicesOld.get(`${API_OLD.finance_balances}`)
                console.log('GET_FINANCE_BALANCES', res.data);
            } catch (err) {
                console.log('store/modules/finance@GET_BALANCES error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
                commit('IS_LOADING', { type: 'balancesLoading', value: false });
            }
        },
        async GET_BALANCES_BY_CURRENCY({ commit }, { currencyCode }) {
            commit('IS_LOADING', { type: 'balancesLoading', value: true });
            try {
                const res = await ServicesOld.get(`${API_OLD.finance_balances}/${currencyCode}`)
                commit('SET_DATA', { type: 'balanceByCurrency', data: res?.data?.data?.data?.available_formatted || `0 ${currencyCode}` })
            } catch (err) {
                console.log('store/modules/finance@GET_BALANCES_BY_CURRENCY error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
                commit('IS_LOADING', { type: 'balancesLoading', value: false });
            }
        },
        async GET_COMMON_BANKS({ commit }) {
            commit('IS_LOADING', { type: 'banksLoading', value: true });
            try {
                const res = await ServicesOld.get(`${API_OLD.finance_banks}`)
                const banks = [];
                const data = res?.data?.data?.data;
                Object.keys(data).length > 0 && Object.keys(data).map((curr) => {
                    banks.push({
                        ...data[curr],
                    })
                })
                commit('SET_DATA', { type: 'banks', data: banks });
            } catch (err) {
                console.log('store/modules/finance@GET_COMMON_BANKS error: ', err.response?.data)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            } finally {
                commit('IS_LOADING', { type: 'banksLoading', value: false });
            }
        },
    },
    namespaced: true
}

export default finance;
