import i18n from "../config/_i18n";

/**
 *
 * @param {*} obj : Object
 * @returns
 */
export const objectToArrayOfObjects = (obj) => {
  let arr = [];
  if (Object.keys(obj).length > 0) {
    arr = Object.keys(obj).map((key) => ({
      label: obj[key],
      name: obj[key],
      value: key,
      id: key,
    }));
  }
  return arr;
};

/**
 *
 * @param {*} res | server response
 * @returns
 */
export const getSuccessServiceMsg = (res) => {
  let msgKey;
  if (res?.data?.message_key) msgKey = `service.${res?.data?.message_key}`;
  else if (res?.data?.message?.message_key)
    msgKey = `service.${res?.data?.message?.message_key}`;
  else if (res?.data?.message) msgKey = res?.data?.message;
  else msgKey = "service.operation_success";
  return i18n.te(msgKey) ? i18n.t(msgKey) : i18n.t("service.operation_success");
};

/**
 *
 * @param {*} res | server response
 * @returns
 */
export const getErrorServiceMsg = (res) => {
  let msgKey;
  if (res?.data?.message_key) msgKey = `service.${res?.data?.message_key}`;
  else if (res?.data?.message?.message_key)
    msgKey = `service.${res?.data?.message?.message_key}`;
  else if (res?.data?.message) msgKey = res?.data?.message;
  else msgKey = "service.fatal_error";
  return i18n.te(msgKey) ? i18n.t(msgKey) : i18n.t("service.fatal_error");
};

// utils/maskUtils.js

/**
 * Mask the full name by showing the first two letters and masking the rest
 * @param {string} fullName - The full name to be masked
 * @returns {string} - The masked full name
 */
export function maskFullName(fullName) {
  const [firstName, lastName] = fullName.split(" ");
  if (firstName && !lastName) return `${firstName.charAt(0)}${"*".repeat(firstName.length - 1)}`;
  if (!firstName || !lastName) return fullName;

  return `${firstName.charAt(0)}${"*".repeat(
    firstName.length - 1
  )} ${lastName.charAt(0)}${"*".repeat(lastName.length - 1)}`;
}

/**
 * Mask the email address by showing the first letter of the local part and masking the rest
 * @param {string} email - The email to be masked
 * @returns {string} - The masked email
 */
export function maskEmail(email) {
  const [localPart, domain] = email.split("@");
  if (!localPart || !domain) return email; // Handle cases with missing parts

  return `${localPart.charAt(0)}${"*".repeat(localPart.length - 1)}@${domain}`;
}

/**
 * Mask the phone number by showing the first 2 and last 2 digits
 * @param {string} phoneNumber - The phone number to be masked
 * @returns {string} - The masked phone number
 */
export function maskPhoneNumber(phoneNumber) {
  const firstPart = phoneNumber.slice(0, 2);
  const numberPart = phoneNumber.slice(2);

  return `${firstPart}${"*".repeat(numberPart.length - 4)}${numberPart.slice(
    -2
  )}`;
}
