<template>
  <div class="col-12 text-center">
    <a class="changeLanguage" @click="changeLanguage('tr')" :class="{'changeLanguageActive': lang === 'tr'}">
      <i class="flag-icon flag-icon-tr"></i> Türkçe
    </a>
    <a class="changeLanguage" @click="changeLanguage('en')" :class="{'changeLanguageActive': lang === 'en'}">
      <i class="flag-icon flag-icon-gb"></i> English
    </a>
  </div>
</template>

<script>
import i18n, { changeLanguages } from '../../config/_i18n';

export default {
  name: 'Language',
  computed: {
    lang() {
      return i18n.locale;
    }
  },
  methods: {
    changeLanguage(lang) {
      changeLanguages(lang, false);
    }
  }
};
</script>

<style scoped lang="scss">
.changeLanguage {
  color: #6b6f82 !important;
  margin: 5px 10px;
  display: inline-block;
}
.changeLanguageActive {
  color: #fff !important;
  margin: 5px 10px;
  display: inline-block;
  font-weight: bold;
}
.changeLanguage:hover {
  cursor: pointer;
  opacity: .7;
}
</style>
