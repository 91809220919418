<template>
    <div class="c__overlay bg-dark">
      <div class="spinner-border text-info" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
 .c__overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    z-index: 99999999;
    width: 100%;
    height: 100%;
 }
</style>