import Vue from "vue";
import ServicesOld from "../../config/_axios_old";
import API_OLD from "../../api/index_old";
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';
import router from '../../router';
// import i18n from '@/config/_i18n'


const userdetails = {
  state: () => ({
    userDetailsLoading: false,
    userDetails: {},
    isLoading: false,
    isSubmitting: false,
    balances: [],
    externalBalanceData: [],
    externalBalanceFormData: {
      user: {},
      currencies: [],
      externalBalance: {},
    },
    attachments: [],
    notificationOptions: {
      user: {},
      notifications: {}
    },
    financialTransactionHistory: [],
    transactionHistory: [],
    transactionHistoryAgent: [],
    editUser: {
      user: {},
      groups: [],
      departments: {},
      countries: []
    },
    spotHistory: []
  }),
  mutations: {
    SET_STATE(state, payload) {
      state[payload.field] = payload.value;
    },
  },
  actions: {
    async getUserDetailsAction({ commit }, { id, isLoading = true }) {
      isLoading && commit('SET_STATE', { field: 'userDetailsLoading', value: true })
      try {
        const res = await ServicesOld.get(API_OLD.user_details(id))
        commit('SET_STATE', { field: 'userDetails', value: res.data })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'userDetailsLoading', value: false })
      }
    },
    async getUserBalancesAction({ commit }, id) {
      commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.get(API_OLD.user_details_balances(id))
        commit('SET_STATE', { field: 'balances', value: res?.data?.balances ?? [] })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async getExternalBalancesAction({ commit }, { id, isLoading = true }) {
      isLoading && commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_external_balances(id), {})
        commit('SET_STATE', { field: 'externalBalanceData', value: res?.data?.data ?? [] })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async externalBalancesCreateAction({ commit }, id) {
      commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.get(API_OLD.user_details_external_balances_create(id))
        commit('SET_STATE', { field: 'externalBalanceFormData', value: { currencies: res?.data?.currencies ?? [] } })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async externalBalancesStoreAction({ commit }, { id, formData }) {
      commit('SET_STATE', { field: 'isSubmitting', value: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_external_balances_store(id), formData)
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isSubmitting', value: false })
      }
    },
    async externalBalancesEditAction({ commit }, { user_id, balance_id }) {
      commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.get(API_OLD.user_details_external_balances_edit({ user_id, balance_id }))
        const { currencies, externalBalance } = res.data;
        commit('SET_STATE', {
          field: 'externalBalanceFormData',
          value: {
            currencies: currencies ?? [],
            externalBalance: externalBalance ?? {}
          }
        })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async externalBalancesUpdateAction({ commit }, { user_id, balance_id, formData }) {
      commit('SET_STATE', { field: 'isSubmitting', value: true })
      try {
        const res = await ServicesOld.patch(API_OLD.user_details_external_balances_edit({ user_id, balance_id }), formData)
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isSubmitting', value: false })
      }
    },
    async externalBalancesDeleteAction({ dispatch }, { user_id, balance_id }) {
      try {
        const res = await ServicesOld.delete(API_OLD.user_details_external_balances_delete({ user_id, balance_id }))
        dispatch('getExternalBalancesAction', { id: user_id, isLoading: false });
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      }
    },
    async getAttachmentsAction({ commit }, { id, isLoading = true }) {
      isLoading && commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_attachments(id), {})
        commit('SET_STATE', { field: 'attachments', value: res?.data?.data ?? [] })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async getNotificationOptionsAction({ commit }, id) {
      commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.get(API_OLD.user_details_notification_options(id))
        const { user, notifications } = res.data;
        commit('SET_STATE', {
          field: 'notificationOptions',
          value: {
            user: user ?? {},
            notifications: notifications ?? {}
          }
        })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async getFinancialTransactionHistoryAction({ commit }, { id, isLoading = true }) {
      isLoading && commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_financial_transaction_history(id), {})
        commit('SET_STATE', { field: 'financialTransactionHistory', value: res?.data?.data ?? [] })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async getTransactionHistoryAction({ commit }, { id, isLoading = true }) {
      isLoading && commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_transaction_history(id), {})
        commit('SET_STATE', { field: 'transactionHistory', value: res?.data?.data ?? [] })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async getAgentTransactionHistoryAction({ commit }, { id, isLoading = true }) {
      isLoading && commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_agent_transaction_history(id), {})
        const resData = res?.data?.data || [];
        const data = resData?.filter(val => val?.description || val?.description?.length > 0) || [];
        commit('SET_STATE', { field: 'agentTransactionHistory', value: data })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async editUserInfoAction({ commit }, id) {
      commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.get(API_OLD.user_details_edit_user_info(id))
        const { user, groups, departments, countries } = res.data;
        commit('SET_STATE', {
          field: 'editUser',
          value: {
            user: user ?? {},
            groups: groups ?? [],
            departments: departments ?? {},
            countries: countries || []
          }
        })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },
    async updateUserInfoAction({ commit, dispatch }, { id, formData }) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.put(API_OLD.user_details_update_user_info(id), formData)
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('getUserDetailsAction', { id, isLoading: false })
        router.push({ path: `/users/${id}` })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    async updateBalanceAction({ commit, dispatch }, { id, formData }) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_balance_edit(id), formData)
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('getUserBalancesAction', id)
        dispatch('getUserDetailsAction', { id, isLoading: false })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    async updateToVIPAction({ commit, dispatch }, userId) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.put(API_OLD.user_details_update_to_vip(userId), {})
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('getUserDetailsAction', { id: userId, isLoading: false })
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    async addNewUserAttachment(_, formData) {
      return await ServicesOld.post(API_OLD.user_details_attachment_add, formData, {
        headers: { 'content-type': 'multipart/form-data' }
      });
    },
    async deleteAttachmentsAction(_, attachmentId) {
      try {
        const res = await ServicesOld.delete(API_OLD.user_details_attachment_delete(attachmentId));  
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      }
    },
    async fetchSpotHistoryAction({ commit }, { id, isLoading = true }) {
      isLoading && commit('SET_STATE', { field: 'isLoading', value: true })
      try {
        const res = await ServicesOld.post(API_OLD.user_details_spot_history(id), {})
        commit('SET_STATE', { field: 'spotHistory', value: res?.data?.data ?? [] })
        return res;
      } finally {
        commit('SET_STATE', { field: 'isLoading', value: false })
      }
    },

  },
  namespaced: true
}

export default userdetails;
