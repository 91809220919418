<template>
	<div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
		<!--begin::Container-->
		<div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
			<!--begin::Copyright-->
			<div class="text-dark order-2 order-md-1">
				<a class="order-1" href="https://miraclecash.com" target="_blank">{{ $t('footer_right') }}</a>
			</div>
			<!--end::Copyright-->
			<!--begin::Nav-->
			<div class="text-dark">
				<router-link to="/">
					<img alt="Logo" src="/assets/media/logos/miracle-more-white.png" width="142" height="63"/>
				</router-link>
				<span class="text-muted font-weight-bold ml-6 mr-3">2021©</span>
				<router-link to="/">{{ $t('title') }}</router-link>
			</div>
			<!--end::Nav-->
		</div>
		<!--end::Container-->
	</div>
</template>

<script>
export default {
  name: 'Footer',
  components: {}
};
</script>
