import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg } from '../../utils';

const bankManagement = {
    state: () => ({
        isLoading: false,
        markets: [],
    }),
    mutations: {
        SET_STATE(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {
        async GET_MARKETS({ commit }, isLoading = true) {
            isLoading && commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.markets_management_list);
                commit('SET_STATE', { name: 'markets', value: res.data?.markets ?? [] });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
    },
    namespaced: true
}

export default bankManagement;