import Vue from 'vue';
import API_OLD from '@/api/index_old';
import ServicesOld from "../../config/_axios_old";
import { objectToArrayOfObjects } from '../../utils';
//import i18n from '@/config/_i18n'
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';
import router from '../../router';

const institutions = {
    state: () => ({
        institutions: [],
        isLoading: true,
        countries: [],
        institutionTypes: [],
        editDataForm: {}
    }),
    mutations: {
        SET_INSTITUTIONS(state, payload) {
            state.institutions = payload;
        },
        SET_INSTITUTIONS_DATA(state, payload) {
            state.countries = payload.countries;
            state.institutionTypes = payload.institutionTypes;
            state.editDataForm = payload.editDataForm;
        },
        SET_LOADING(state, payload) {
            state.isLoading = payload;
        }
    },
    actions: {
        async GET_INSTITUTIONS({ commit }) {
            await ServicesOld.post(API_OLD.inst_data)
              .then(res => {
                commit('SET_INSTITUTIONS', res.data.data);
              })
              .catch(err => {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
              });
        },
        GET_INSTITUTIONS_DATA({ commit }, { type = "create", id = "" }) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let url = type === "create" ? `${API_OLD.institutions_new_get}` : `${API_OLD.institutions}/${id}/edit`;
                ServicesOld.get(url)
                    .then(res => {

                        let editDataForm = {};
                        
                        if (res.data.institutions) {
                            editDataForm = res.data.institutions;
                        }
                        const payload = {
                            countries: res.data.countries,
                            institutionTypes: objectToArrayOfObjects(res.data?.institutionTypes),
                            editDataForm
                        };
                        commit('SET_INSTITUTIONS_DATA', payload);
                        resolve('success')
                    })
                    .catch(err => {
                        console.log("err: ",err.response)
                        if (err?.response) {
                            Vue.$toast.error(getErrorServiceMsg(err.response));
                        }
                        reject('error')
                    }).finally(() => {
                        commit('SET_LOADING', false);
                    })
            })
        },
        async POST_INSTITUTIONS(_, params) {
            try {
                const resp = await ServicesOld.post(`${API_OLD.institutions_new}`, params);
                //console.log("post: ", resp.data)
                Vue.$toast.success(getSuccessServiceMsg(resp));
                router.push({ name: 'Institutions' });
            } catch (err) {
                console.log("err: ", err)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            }
        },
        async UPDATE_INSTITUTIONS(_, { id, form }) {
            try {
                const res = await ServicesOld.patch(`${API_OLD.institutions}/${id}`, form)
                console.log("post: ", res.data)
                Vue.$toast.success(getSuccessServiceMsg(res));
            } catch (err) {
                console.log("err: ", err)
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
            }
        },
        async DELETE_INSTITUTIONS(_, id) {
            try {
                const res = await ServicesOld.delete(`${API_OLD.institutions}/${id}`);    
                // console.log("employess delete: ",res.data)
                Vue.$toast.success(getSuccessServiceMsg(res));
                // dispatch("GET_INSTITUTIONS")
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                return err;
            }
          },

    },
    namespaced: true
}

export default institutions;