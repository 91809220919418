import Vue from 'vue';
import API_OLD from '@/api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../utils';

const profile = {
    state: () => ({
        isLoading: false,
        security_settings: {},
    }),
    mutations: {
        SET_SECURITY_SETTINGS(state, payload) {
            state.security_settings = payload;
        },
        SET_LOADING(state, payload) {
            state.isLoading = payload;
        }
    },
    actions: {
        GET_SECURITY_SETTINGS({ commit }) {
            commit('SET_LOADING', true)
            ServicesOld.get(`${API_OLD.profile_security_settings}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(res => {
                    // console.log("GET_SECURITY_SETTINGS: ", res.data.data.secret)
                    commit('SET_SECURITY_SETTINGS', res.data.data);
                })
                .catch(err => {
                    if (err?.response) {
                        Vue.$toast.error(getErrorServiceMsg(err.response));
                    }
                })
                .finally(() => {
                    commit('SET_LOADING', false)
                })
        },
        POST_SECURITY_SETTINGS({ dispatch }, {formData}) {
            ServicesOld.post(`${API_OLD.profile_security_settings}`, formData)
                .then(res => {
                    Vue.$toast.success(getSuccessServiceMsg(res));
                    dispatch('GET_SECURITY_SETTINGS')
                })
                .catch(err => {
                    if (err?.response) {
                        Vue.$toast.error(getErrorServiceMsg(err.response));
                    }
                });
        },
        CHANGE_PASSWD_SEND_SMS({ commit }, { form }) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                ServicesOld.post(`${API_OLD.change_password_send_sms}`, form)
                    .then(res => {
                        console.log("CHANGE_PASSWD_SEND_SMS: ", res.data)
                        resolve('success')
                    })
                    .catch(err => {
                        reject('error')
                        if (err?.response) {
                            Vue.$toast.error(getErrorServiceMsg(err.response));
                        }
                    })
                    .finally(() => {
                        commit('SET_LOADING', false)
                    })
            })
        },
        CHANGE_PASSWD_SEND_CONFIRM({ commit }, { form }) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                ServicesOld.post(`${API_OLD.change_password_send_confirmation}`, form)
                    .then(res => {
                        console.log("CHANGE_PASSWD_SEND_CONFIRM: ", res.data)
                        Vue.$toast.success(res.data?.message || 'Operation performed successfully!');
                        resolve('success')
                    })
                    .catch(err => {
                        reject('error')
                        if (err?.response) {
                            Vue.$toast.error(getErrorServiceMsg(err.response));
                        }
                    })
                    .finally(() => {
                        commit('SET_LOADING', false)
                    })
            })
        }

    },
    namespaced: true
}

export default profile;