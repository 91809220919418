import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';

const permissionsManagement = {
    state: () => ({
        isLoading: false,
        isSubmitting: false,
        permissions: [],
        storeData: {},
        updateData: {}
    }),
    mutations: {
        SET_STATE(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {
        async GET_PERMISSIONS_DATA({ commit }) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.post(API_OLD.permissions_list, {});
                commit('SET_STATE', { name: 'permissions', value: res.data.data });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async GET_STORE_DATA({ commit }) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.permissions_store_data);
                const instEntries = Object.entries(res.data?.institutions);                
                const institutions = instEntries.length > 0 ? instEntries.map(([key, value]) => ({ label: key, name: key, id: value, ...value })) : []
                const payloadVal = {
                    institutions,
                    institutionPermissionTypes: res.data?.institutionPermissionTypes || []
                }
                commit('SET_STATE', { name: 'storeData', value: payloadVal });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async STORE_PERMISSION({ commit }, params) {
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const res = await ServicesOld.post(API_OLD.permissions_store, params);
                Vue.$toast.success(getSuccessServiceMsg(res));
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async GET_UPDATE_DATA({ commit }, id) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.permissions_update_data(id));
                const instEntries = Object.entries(res.data?.institutions);                
                const institutions = instEntries.length > 0 ? instEntries.map(([key, value]) => ({ label: key, name: key, id: value, ...value })) : []
                const payloadVal = {
                    institutions,
                    permission: res.data.permission,
                    institutionPermissionTypes: res.data?.institutionPermissionTypes || []
                }
                commit('SET_STATE', { name: 'updateData', value: payloadVal });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async UPDATE_PERMISSION({ commit }, params) {
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const res = await ServicesOld.patch(API_OLD.permissions_update(params.id), params.formData);
                Vue.$toast.success(getSuccessServiceMsg(res));
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async DELETE_PERMISSION({ dispatch }, id) {
            try {
                const res = await ServicesOld.delete(API_OLD.permissions_delete(id));
                Vue.$toast.success(getSuccessServiceMsg(res));
                dispatch('GET_PERMISSIONS_DATA');
                //window.location.reload()
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            }
        },
    },
    namespaced: true
}

export default permissionsManagement;