<template>
  <button
    class="font-weight-bolder text-uppercase px-9 py-4"
    :class="btnClass"
    type="button"
    :disabled="isSubmitting"
    @click.prevent="$emit('click')"
  >
    <span
      v-if="isSubmitting"
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
    ></span>
    {{ text || $t('commons.submit') }}
  </button>
</template>
<script>
export default {
  props: {
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    btnClass: {
      type: String,
      default: "btn btn-success",
    },
  },
  data() {
    return {};
  },
};
</script>