import axios from 'axios';
import Vue from 'vue';
const CancelToken = axios.CancelToken;
const AxiosTokenSource = CancelToken.source();
import i18n from '@/config/_i18n'
const BASE_API_URL = process.env.VUE_APP_PANEL_API_URI;
const Services = axios.create({baseURL: String(BASE_API_URL), cancelToken: AxiosTokenSource.token});

//let lang = sessionStorage.getItem('lang') || 'en';

Services.interceptors.request.use(
    config => {
        const token = localStorage.getItem('userToken');
        const auth = token ? `Bearer ${token}` : '';
        config.headers.common['Authorization'] = auth;
        //config.headers.common['X-Localization'] = lang;
        return config;
    },
    error => Promise.reject(...error)
);

Services.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && error.response.data.message === "Expired JWT Token") {
        AxiosTokenSource.cancel('401 error');
        localStorage.removeItem('userToken');
        window.location = '/';
      }
      if (error.response.status === 401) {
        window.location = '/no-permission';
      }
      if (error.response.status === 404 || error.response.status === 405 || error.response.status === 500) {
        Vue.$toast.error(i18n.t('service.fatal_error'));
      }
      throw error;
    }
);

export default Services;