// import Vue from 'vue';
// import Services from '@/config/_axios';
import API_OLD from '@/api/index_old';
import Vue from "vue";
import i18n from '@/config/_i18n'

import ServicesOld from "../../config/_axios_old";
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';

const transactions = {
  state: () => ({
    isLoading: true,
    calculated: null,
    createTransactionData: {
      departments: [],
      wallet: {},
      defaultCurrency: {},
      cryptoCurrencies: [],
      currencies: [],
      balanceableCurrencies: [],
      calculatableCurrencies: [],
      allCurrencies: [],
      transactionsList: []
    },
    transactionSummary: {},
    transactionReqParams: null,
    showDetailsModal: {
      visible: false, id: ''
    },
    detailsModalData: {},
    pendingTransactions: [],
    outPurchasesCreateData: {
      currencies: [],
      amount: 0
    },
    smsModalVisible: false,
    smsConfirmForm: {
      sms: '',
      action: '',
      smart: ''
    },
    updateStatus: '',
    lastTransaction: [],
    customersLoading: false,
    customers: [],
    cryptoAuditModalVisible: false,
    cryptoAudits: [],
  }),
  mutations: {
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_CALCULATED(state, payload) {
      state.calculated = payload;
    },
    SET_CREATE_TRANSACTION_DATA(state, payload) {
      state.createTransactionData = payload;
    },
    FILTER_CALCULATABLE_CURRENCIES(state, payload) {
      state.createTransactionData.calculatableCurrencies.forEach(function (v) { delete v['$isDisabled'] });
      const findCalculatableCurrency = state.createTransactionData.calculatableCurrencies.find(val => val.code === payload);
      if(findCalculatableCurrency) {
        state.createTransactionData.calculatableCurrencies.find(val => `${val.code}` === `${payload}`)["$isDisabled"] = true;
      }
    },
    SET_TRANSACTION(state, payload) {
      state.transactionsList = payload;
    },
    SET_TRANSACTION_REQUEST_PARAMS(state, params) {
      state.transactionReqParams = params;
    },
    SET_TRANSACTION_SUMMARY(state, transaction) {
      state.transactionSummary = transaction;
    },
    detailsModal(state, payload) {
      state.showDetailsModal = { id: payload.id, visible: payload.visible };
    },
    SET_DETAILS_MODAL_DATA(state, payload) {
      state.detailsModalData = payload;
    },
    SET_OUTPURCHASES_CREATE_DATA(state, { currencies, amount }) {
      state.outPurchasesCreateData = { currencies, amount }
    },
    SET_PENDING_TRANSACTIONS(state, data) {
      state.pendingTransactions = data
    },
    TOGGLE_SMS_MODAL(state, payload) {
      state.smsModalVisible = payload;
    },
    SET_UPDATESTATUS_AND_SMSACTION(state, status) {
      state.updateStatus = status;
      let action = '1';
      if (status.toString() === '30') {
        action = '2';
      }
      state.smsConfirmForm = { ...state.smsConfirmForm, action }
    },
    SET_LAST_TRANSACTION(state, payload) {
      state.lastTransaction = payload;
    },
    RESET_SMS_FORM(state) {
      state.smsConfirmForm = {
        sms: '',
        action: '',
        smart: ''
      }
    },
    SET_CUSTOMERS_LOADING(state, payload) {
      state.customersLoading = payload;
    },
    SET_CUSTOMERS(state, payload) {
      state.customers = payload;
    },
    UPDATE_CALCULATED_WALLET(state, payload) {
      state.calculated = {
        ...state.calculated,
        wallet: payload
      }
    },
    SET_STATE(state, { field, value }) {
      state[field] = value;
    },
    TOGGLE_AUDIT_MODAL(state, payload) {
      state.cryptoAuditModalVisible = payload;
      if (!payload) {
        state.cryptoAudits = [];
      }
    },
  },
  actions: {
    async POST_CALCULATE({ commit }, params) {
      commit('IS_LOADING', true);
      try {
        const res = await ServicesOld.post(API_OLD.transactions_calculate, params);
        commit('SET_CALCULATED', res.data);
        commit('IS_LOADING', false);
        return res.data;
      } catch (err) {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
          throw err;
      } finally {
        commit('IS_LOADING', false);
      }
    },
    CREATE_TRANSACTION({ commit, state }) {
      commit('IS_LOADING', true);
      return new Promise((resolve, reject) => {
        ServicesOld.post(API_OLD.create_transaction, state.transactionReqParams)
          .then(res => {
            commit('IS_LOADING', false);
            commit('SET_CALCULATED', null);
            commit('SET_TRANSACTION_REQUEST_PARAMS', null);
            commit('SET_TRANSACTION_SUMMARY', null);
            Vue.$toast.success(getSuccessServiceMsg(res));
            resolve(res);
          })
          .catch(err => {
            commit('IS_LOADING', false);
            console.log("err: ", err)
            Vue.$toast.error(getErrorServiceMsg(err?.response));
            reject(err);
          })
      })
    },
    SET_TRANSACTION_SUMMARY({ commit }, transaction) {
      commit('SET_TRANSACTION_SUMMARY',transaction);
    },
    async GET_CREATE_TRANSACTION_DATA({ commit }) {
      commit('IS_LOADING', true);
      try {
        const res = await ServicesOld.post(API_OLD.get_create_transaction_data, {});
        const payload = res.data;
        let cryptoCurrencies = [];
        if (res.data?.cryptoCurrencies.length > 0) {
          res.data.cryptoCurrencies.forEach(curr => {
            const buyData = curr.buy;
            const sellData = curr.sell;
            cryptoCurrencies.push({ id: buyData.id, code: buyData.code, type: 'buy', action: sellData.action, message: buyData.message, decimal: buyData.decimal })
            cryptoCurrencies.push({ id: sellData.id, code: sellData.code, type: 'sell', action: buyData.action, message: sellData.message, decimal: sellData.decimal })
          })
        }
        payload.cryptoCurrencies = cryptoCurrencies;
        commit('SET_CREATE_TRANSACTION_DATA', payload);
      } catch (err) {
          console.log("GET_CREATE_TRANSACTION_DATA error: ", err)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      } finally {
        commit('IS_LOADING', false);
      }
    },
    async GET_TRANSACTION({ commit }) {
      commit('IS_LOADING', true);
      await ServicesOld.post(API_OLD.transactions_data)
        .then(res => {
          // console.log("SET_TRANSACTION: ",res.data)
          commit('IS_LOADING', false);
          commit('SET_TRANSACTION', res.data.data);
        })
        .catch(err => {
            console.log("err: ", err)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    async GET_LAST_TRANSACTION({ commit }) {
      commit('IS_LOADING', true);
      await ServicesOld.get(API_OLD.merchant_last)
        .then(res => {
          commit('IS_LOADING', false);
          commit('SET_LAST_TRANSACTION', res.data.data);
        })
        .catch(err => {
            console.log("err: ", err)
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
        });
    },
    async GET_DETAIL_MODAL_DATA({ commit }, transaction_id) {
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.get(`transactions/pending/${transaction_id}/modal`);
        // console.log("GET_DETAIL_MODAL_DATA: ", res.data.cryptobank)
        commit('SET_DETAILS_MODAL_DATA', res.data)
        commit('detailsModal', { id: transaction_id, visible: true});
      } catch (err) {
          console.log("err: ", err)
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    async GET_PENDING_TRANSACTIONS({ commit }) {
      try {
        const res = await ServicesOld.get('transactions/pending')
        // console.log("GET_PENDING_TRANSACTIONS: ",res.data.data)
        commit('SET_PENDING_TRANSACTIONS', res.data.data)
      } catch (err) {
        console.log(err.response.data);
      }
    },
    async GET_OUTPURCHASES_CREATE_DATA({ commit }, { coin_id, type, coin_code }) {
      try {
        const res = await ServicesOld.get(`financecrm/out-purchases/create?coin_id=${coin_id}&type=${type}&coin_code=${coin_code}`);
        const { currencies, amount } = res.data;
        // console.log('GET_OUTPURCHASES_CREATE_DATA: ', res.data.currencies);
        commit('SET_OUTPURCHASES_CREATE_DATA', { currencies: currencies || [], amount: amount || 0 });
      } catch (err) {
        console.log(err.response.data);
      }
    },
    async POST_OUTPURCHASES(_, formData) {
      try {
        const res = await ServicesOld.post('financecrm/out-purchases/one/create', formData);
        console.log('POST_OUTPURCHASES: ', res?.data);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (err) {
        console.log('POST_OUTPURCHASES error: ', err);
        Vue.$toast.error(getErrorServiceMsg(err?.response));
        throw err;
      }
    },

    /**
     *
     * @param {*} param0
     * @param {*} param1
     * param1.status = 20 => complete
     * param1.status = 30 => cancel
     */
    async TRANSACTION_PENDING_SMS_CODE({ commit }, { transaction_id, status = '20' }) {
      // 1. send sms for transaction_id
      // 2. open sms confirm modal
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        const res = await ServicesOld.post(`transactions/pending/${transaction_id}/sms`, {})
        commit('TOGGLE_SMS_MODAL', true);
        commit('SET_UPDATESTATUS_AND_SMSACTION', status)
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error?.response));
        throw error;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },
    async TRANSACTION_PENDING_SMS_CONFIRM({ state, commit }, transaction_id) {
      // 1. confirm sms with transaction_id and state smsConfirmForm
      // 2. close smsModal and dispatch for APPROVE__DECLINE_TRANSACTION
      if (!state.smsConfirmForm.sms) {
        Vue.$toast.error(i18n.t('merchant.btn_approve_modal'));
        return;
      }
      commit('global/SET_OVERLAY_LOADER', true, { root: true })
      try {
        let res = await ServicesOld.post(`transactions/pending/${transaction_id}/modal`, state.smsConfirmForm);
        // console.log(res)
        if(res.data.status == 'ok') {
          // await dispatch('GET_TRANSACTION')
          // await dispatch('GET_DETAIL_MODAL_DATA', transaction_id)
          commit('detailsModal', { id: '', visible: false });
          commit('TOGGLE_SMS_MODAL', false);
          commit('RESET_SMS_FORM');
          Vue.$toast.success(getSuccessServiceMsg(res));
        } else {
          Vue.$toast.error(getErrorServiceMsg(res));
        }
        //await dispatch('APPROVE_DECLINE_TRANSACTION', transaction_id)
      } catch (error) {
        console.log('TRANSACTION_PENDING_SMS_CONFIRM error: ', error?.response?.data)
        Vue.$toast.error(getErrorServiceMsg(error?.response));
        throw error;
      } finally {
        commit('global/SET_OVERLAY_LOADER', false, { root: true })
      }
    },

    async APPROVE_DECLINE_TRANSACTION({ dispatch, commit, state }, transaction_id) {
      // 1. approve or cancel transaction
      // close detail modal
      // dispatch GET_TRANSACTION
      const status = state.updateStatus;
      try {
        const res = await ServicesOld.put(`transactions/${transaction_id}/update-status/${status}`, { id: transaction_id, status });
        await dispatch('GET_TRANSACTION')
        await dispatch('GET_DETAIL_MODAL_DATA', transaction_id)
        commit('detailsModal', { id: '', visible: false });
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (error) {
        console.log('APPROVE__DECLINE_TRANSACTION error: ', error.response?.data)
        Vue.$toast.error(getErrorServiceMsg(error?.response));
        throw error;
      }
    },

    async CREATE_DEPOSIT_TRANSACTION({commit}, formData) {
      commit('IS_LOADING', true);
      try {
        const res = await ServicesOld.post(API_OLD.create_branch_deposit_transaction, formData)
        commit('IS_LOADING', false);
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch (error) {
        commit('IS_LOADING', false);
        console.log('APPROVE__DECLINE_TRANSACTION error: ', error.response?.data)
        Vue.$toast.error(getErrorServiceMsg(error?.response));
        throw error;
        
      }
    },

    async searchCustomersAction({commit}, params) {
      commit('SET_CUSTOMERS_LOADING', true);
      try {
        const res = await ServicesOld.get(`${API_OLD.transactions}/searchcustomer?fullname=${params.query}&currencyId=${params.currencyID}`)
        commit('SET_CUSTOMERS', res.data?.results || []);
        return res.data;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error?.response));
        throw error;
      } finally {
        commit('SET_CUSTOMERS_LOADING', false);
      }
    },
    async GET_CRYPTOBANK_AUDITS({ commit }, id) {
      commit("global/SET_OVERLAY_LOADER", true, { root: true });
      try {
        const res = await ServicesOld.post('audit/modal', {
          id,
          model: "App\\Models\\Finance\\Cryptobank",
        });
        commit("SET_STATE", { field: "cryptoAudits", value: res.data?.audits ?? [] });
        commit("TOGGLE_AUDIT_MODAL", true);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        throw err;
      } finally {
        commit("global/SET_OVERLAY_LOADER", false, { root: true });
      }
    },
  },
  namespaced: true
}

export default transactions;
