import Vue from "vue";
import ServicesOld from "../../config/_axios_old";
import API_OLD from "@/api/index_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg } from "../../utils";

const global = {
  state: () => ({
    isLoading: true,
    searchUserIsLoading: false,
    userList: [],
    showCreateWalletModal: false,
    lang: sessionStorage.getItem("lang"),
    currency: [],
    crypto: [],
    isLoadingOverlay: false,
  }),
  mutations: {
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SEARCH_IS_LOADING(state, payload) {
      state.searchUserIsLoading = payload;
    },
    SET_CREATE_WALLET_MODAL(state, payload) {
      state.showCreateWalletModal = payload;
    },
    SET_CURRENCY(state, payload) {
      state.currency = [];
      for (let key in payload.data) {
        let object = payload.data[key];
        state.currency.push(object);
      }
    },
    SET_OVERLAY_LOADER(state, payload) {
      state.isLoadingOverlay = payload;
    },
  },
  actions: {
    GET_EXCEL({ commit }, params) {
      commit("SET_OVERLAY_LOADER", true);
      ServicesOld.get(params.url, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
        params: params?.reqPayload || {},
      })
        .then((response) => {
          commit("IS_LOADING", false);
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = params.label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        })
        .finally(() => {
          commit("SET_OVERLAY_LOADER", false);
        });
    },
    async GET_PDF({ commit }, params) {
      commit("SET_OVERLAY_LOADER", true);
      try {
        const response = await ServicesOld.get(params.url, {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        });
        commit("IS_LOADING", false);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = params.label;
        link.click();
        URL.revokeObjectURL(link.href);
        return response;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit("SET_OVERLAY_LOADER", false);
      }
    },
    async GET_MULTI({ commit }, params) {
      commit("SET_OVERLAY_LOADER", true);
      try {
        const response = await ServicesOld.get(params.url, {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        });
        commit("IS_LOADING", false);
        const blob = new Blob([response.data], { type: params.type });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = params.label;
        link.click();
        URL.revokeObjectURL(link.href);
        return response;
      } catch (err) {
        if (err?.response) {
          Vue.$toast.error(getErrorServiceMsg(err.response));
        }
        throw err;
      } finally {
        commit("SET_OVERLAY_LOADER", false);
      }
    },
    GET_CURRENCY({ commit }) {
      return new Promise((resolve, reject) => {
        ServicesOld.get(`${API_OLD.finance_currencies}`)
          .then((res) => {
            commit("SET_CURRENCY", res.data?.data);
            commit("IS_LOADING", false);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            if (err?.response) {
              Vue.$toast.error(getErrorServiceMsg(err.response));
            }
          });
      });
    },
    DOWNLOAD_XLSX_POST({ commit }, params) {
      commit("SET_OVERLAY_LOADER", true);
      ServicesOld.post(params.url, {}, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
        params: params?.reqPayload || {},
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = params.label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          if (err?.response) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        })
        .finally(() => {
          commit("SET_OVERLAY_LOADER", false);
        });
    },
  },
  getters: {
    getCrypto(state) {
      return state.currency.filter(function (currency) {
        return currency.crypto && currency.quicksale;
      });
    },
    getCurrency(state) {
      return state.currency.filter(function (currency) {
        return currency;
      });
    },
  },
  namespaced: true,
};

export default global;
