import Vue from 'vue';
import API_OLD from '../../api/index_old';
import ServicesOld from "../../config/_axios_old";
//import i18n from '@/config/_i18n'
import { getErrorServiceMsg, getSuccessServiceMsg } from '../../utils';
import router from '../../router/index';

const bankManagement = {
    state: () => ({
        isLoading: false,
        banks: [],
        isSubmitting: false,
        countries: [],
        bank: {}
    }),
    mutations: {
        SET_STATE(state, payload) {
            state[payload.name] = payload.value;
        },
    },
    actions: {
        async GET_BANKS({ commit }, isLoading = true) {
            isLoading && commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.post(API_OLD.bank_management_list, {});
                commit('SET_STATE', { name: 'banks', value: res.data?.data ?? [] });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async GET_STORE_DATA({ commit }) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.bank_management_store_data);
                commit('SET_STATE', { name: 'countries', value: res.data?.countries ?? [] });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async STORE({ commit }, params) {
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const res = await ServicesOld.post(API_OLD.bank_management_store, params);
                Vue.$toast.success(getSuccessServiceMsg(res));
                router.push({ name: 'BankManagement' })
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async GET_UPDATE_DATA({ commit }, id) {
            commit('SET_STATE', { name: 'isLoading', value: true });
            try {
                const res = await ServicesOld.get(API_OLD.bank_management_update(id));
                const { countries, bank } = res.data;
                commit('SET_STATE', { name: 'countries', value: countries ?? [] });
                commit('SET_STATE', { name: 'bank', value: bank ?? {} });
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isLoading', value: false });
            }
        },
        async UPDATE({ commit }, params) {
            const { id, formData } = params
            commit('SET_STATE', { name: 'isSubmitting', value: true });
            try {
                const res = await ServicesOld.patch(API_OLD.bank_management_update(id), formData);
                Vue.$toast.success(getSuccessServiceMsg(res));
                router.push({ name: 'BankManagement' })
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            } finally {
                commit('SET_STATE', { name: 'isSubmitting', value: false });
            }
        },
        async DESTROY(_, id) {
            try {
                const res = await ServicesOld.delete(API_OLD.bank_management_destroy(id));
                Vue.$toast.success(getSuccessServiceMsg(res));
                // dispatch('GET_BANKS', false);
                return res;
            } catch (err) {
                if (err?.response) {
                    Vue.$toast.error(getErrorServiceMsg(err.response));
                }
                throw err;
            }
        },
    },
    namespaced: true
}

export default bankManagement;